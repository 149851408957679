export const getStravaIdFromSub = (subject: string | undefined) => {
  if (!subject) {
    return '';
  }

  // subject strings are: "oauth2|Strava|<id>"
  const [oauth2, strava, id] = subject.split('|');

  if (oauth2 === 'oauth2' && strava === 'Strava') {
    return id;
  }

  console.error(`Couldn't get Strava id from subject '${subject}'`);
  return '';
};
