import React, { useEffect, useState, Suspense } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { VStack, Box, Grid } from '@chakra-ui/react';

import { CyclingRoute, CyclingRouteLight } from '../../state/route/route';
import { BoundingBox } from '../../polyline';
import { getStravaIdFromSub } from '../../auth/utils';
import routesService from '../../state/route/service';
import { RouteCardDetailedFallback } from '../../components/RouteCardDetailed';
import { CollectionResSinglePage, Resource } from '../../state/api';
import { RouteCardDetailedWithResource } from '../../components/RouteCardDetailed/with-resource';
import { RoutesMapWithResource } from '../../components/RoutesMap/with-resrource';
import { useHistory } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { RoutesGridFallback } from '../../components/RoutesGrid/fallback';
import { RoutesGridWithResource } from '../../components/RoutesGrid/with-resource';

export const MapTab: React.FC = () => {
  const { getAccessTokenSilently, user } = useAuth0();
  const history = useHistory();
  const athleteId = getStravaIdFromSub(user?.sub);
  const [bounds, setBounds] = useState<BoundingBox>();
  const [routeResource, setRouteResource] = useState<Resource<CyclingRoute>>();
  const [routesResource, setRoutesResource] = useState<
    Resource<CollectionResSinglePage<CyclingRouteLight>>
  >();

  useEffect(() => {
    let bottomLeft = { lat: 0, lon: 0 };
    let topRight = { lat: 0, lon: 0 };

    if (bounds) {
      bottomLeft = { lat: bounds.bottomLeft.lat, lon: bounds.bottomLeft.lon };
      topRight = { lat: bounds.topRight.lat, lon: bounds.topRight.lon };
    }

    const resource = routesService.fetchGeo({
      accessTokenFn: getAccessTokenSilently,
      athleteId: athleteId,
      boundingBox: {
        bottomLeft: bottomLeft,
        topRight: topRight,
      },
      types: [], //TODO support type filtering
    });

    setRoutesResource(resource);
  }, [bounds]);

  const onInteractionStateChange = function (bb: BoundingBox) {
    setBounds(bb);
  };

  const handleRouteSelection = function (route: CyclingRouteLight) {
    const resource = routesService.fetchById({
      accessTokenFn: getAccessTokenSilently,
      athleteId: athleteId,
      routeId: route._id,
    });

    setRouteResource(resource);
  };

  const handleOnMapClick = function () {
    setRouteResource(undefined);
  };

  const handleOnGoToRoute = function (routeStravaId: string) {
    history.push(`/route/${routeStravaId}`);
  };

  const handleOnAddToCompareList = function (route: CyclingRoute) {};

  return (
    <>
      <Helmet>
        <title>Explore | Map</title>
      </Helmet>

      <Box position="relative">
        <VStack>
          {routesResource ? (
            <RoutesMapWithResource
              resource={routesResource}
              height={600}
              width={500}
              onInteractionStateChange={onInteractionStateChange}
              onRouteSelected={handleRouteSelection}
              onMapClick={handleOnMapClick}
            />
          ) : null}

          <Suspense fallback={<RouteCardDetailedFallback />}>
            {routeResource ? (
              <RouteCardDetailedWithResource
                resource={routeResource}
                onGoToRoute={handleOnGoToRoute}
                onAddToCompareList={handleOnAddToCompareList}
              />
            ) : null}
          </Suspense>
        </VStack>
      </Box>
    </>
  );
};
