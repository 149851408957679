import React from 'react';
import { Box, Text, VStack, HStack, Link } from '@chakra-ui/react';
import { Link as RouterLink } from 'react-router-dom';

interface IconProps {
  boxSize: number;
  color: string;
}

export interface Props {
  code: string;
  title: string;
  subtitle: string;
  icon: React.FC<IconProps>;
}

export const GenericError: React.FC<Props> = function ({ title, subtitle, code, icon }) {
  const I = icon;

  return (
    <Box pt={16} pb={16} maxW="4xl">
      <HStack spacing={24}>
        <VStack alignItems="start">
          <Text fontSize="5xl" fontWeight="bold">
            {title}
          </Text>
          <Text fontSize="3xl">{subtitle}</Text>
          <Text fontSize="xl" color="gray.400">
            Error code: {code}
          </Text>

          <Box pt={4} textAlign="left">
            <Text>Here are some helpful links instead:</Text>
            <Link as={RouterLink} to={'/'} color="primary.500">
              Dashboard
            </Link>
            <br />
            <Link as={RouterLink} to={'/explore'} color="primary.500">
              Explore your routes
            </Link>
          </Box>
        </VStack>
        <Box>
          <I boxSize={40} color="gray.300" />
        </Box>
      </HStack>
    </Box>
  );
};
