import { useState } from 'react';
import { CollectionRes } from '../state/api';

export interface PageCursor {
  cursor: string;
  cursorNext: string;
}

export interface PaginableCollection {
  currentPageCursor: PageCursor;
  pages: PageCursor[];
}

export interface Page<T> {
  cursor: PageCursor;
  items: T[];
}

interface PaginationHook<T> {
  currentPageNumber: number;
  pagesIndexes: number[];
  isCurrentPageFirst: boolean;
  isCurrentPageLast: boolean;
  getCursorForNextPage: () => string;
  getCursorForPreviousPage: () => string;
  getCursorForPage: (pageNumber: number) => string;
  currentPageItems: T[];
  hasMultiplePages: boolean;
  hasMoreItems: boolean;
  handleNewPageResponse: (collRes: CollectionRes<T>) => void;
  debug: () => void;
}

interface State<T> {
  currentPage: Page<T>;
  collPages: PaginableCollection;
}

export const usePagination = (): PaginationHook<any> => {
  const [state, setState] = useState<State<any> | null>(null);
  const pagColl = state ? state.collPages : null;
  const currentPage = state ? state.currentPage : null;
  const firstPage = pagColl?.pages[0];
  const lastPage = pagColl?.pages[pagColl?.pages.length - 1];

  const currentPageIndex = pagColl
    ? pagColl.pages.findIndex((p) => p.cursor === pagColl.currentPageCursor.cursor)
    : -1;
  const pagesIndexes = pagColl
    ? pagColl.pages.reduce<number[]>((acc, cur, index) => {
        acc.push(index + 1);
        return acc;
      }, [])
    : [];

  const isCurrentPageFirst = pagColl
    ? pagColl.currentPageCursor.cursor === firstPage?.cursor
    : false;
  const isCurrentPageLast = pagColl ? pagColl.currentPageCursor.cursor === lastPage?.cursor : false;
  const hasMoreItems = pagColl ? pagColl.currentPageCursor.cursorNext !== '' : false;
  const currentPageItems = currentPage ? currentPage.items : [];
  const hasMultiplePages = pagColl
    ? pagColl.pages.length > 1 || pagColl.currentPageCursor.cursorNext !== ''
    : false;

  const getCursorForNextPage = function () {
    if (!pagColl) {
      return '';
    }

    return pagColl.pages[currentPageIndex].cursorNext;
  };

  const getCursorForPreviousPage = function () {
    if (!pagColl) {
      return '';
    }

    return pagColl.pages[currentPageIndex - 1].cursor;
  };

  const getCursorForPage = function (pageNumber: number) {
    if (!pagColl) {
      return '';
    }

    return pagColl.pages[pageNumber - 1].cursor;
  };

  const handleNewPageResponse = function (collRes: CollectionRes<any>) {
    const indexOfCursor = state?.collPages.pages.findIndex(
      (p) => p.cursor === collRes.pagination.cursor,
    );
    const currentPages = state ? state.collPages.pages : [];

    if (indexOfCursor === undefined || indexOfCursor === -1) {
      setState({
        currentPage: { items: collRes.items, cursor: collRes.pagination },
        collPages: {
          pages: [...currentPages, collRes.pagination],
          currentPageCursor: collRes.pagination,
        },
      });
    }
  };

  const debug = function () {
    console.log('usePagination current values:');
    console.log(`  - current page number: ${currentPageIndex + 1}`);
    console.log(`  - pages indexes: ${pagesIndexes.toString()}`);
    console.log(`  - is current page first: ${isCurrentPageFirst}`);
    console.log(`  - is current page last: ${isCurrentPageLast}`);
    console.log(`  - has more items: ${hasMoreItems}`);
    console.log(`  - has multiple pages: ${hasMultiplePages}`);
    console.log(`  - current page items count: ${currentPageItems.length}`);
  };

  return {
    currentPageNumber: currentPageIndex + 1,
    pagesIndexes: pagesIndexes,
    isCurrentPageFirst: isCurrentPageFirst,
    isCurrentPageLast: isCurrentPageLast,
    hasMoreItems: hasMoreItems,
    getCursorForNextPage: getCursorForNextPage,
    getCursorForPreviousPage: getCursorForPreviousPage,
    getCursorForPage: getCursorForPage,
    currentPageItems: currentPageItems,
    hasMultiplePages: hasMultiplePages,
    handleNewPageResponse: handleNewPageResponse,
    debug: debug,
  };
};
