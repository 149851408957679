import React from 'react';
import { GenericError } from '../GenericError';
import { ForbiddenIcon } from '../Icons';

export const NotAuthorisedError: React.FC = function () {
  return (
    <GenericError
      title="Not authorised!"
      subtitle="It seems you shouldn't be trying to access this."
      code="403"
      icon={ForbiddenIcon}
    />
  );
};
