import React, { useState } from 'react';
import { Box, HStack, FormLabel, Spacer, Button } from '@chakra-ui/react';
import { SliderFilter } from './SliderFilter';
import { DistanceFormatter } from '../DistanceFormatter';
import { AltitudeFormatter } from '../AltitudeFormatter';
import { ToggleButton } from './ToogleButton';
import { CyclingRouteType } from '../../state/route/route';
import { defaultRoutesFilterState, RoutesFilterState } from '../../state/route/route-filters';

//TODO validate filter ranges, for example, min elevation must be less than max elevation, the same for distance
//TODO I want to get rid of the apply button, but didn't get the onChangeEnd of the slides to work. I think is related to this: https://github.com/chakra-ui/chakra-ui/issues/2649

export interface RoutesFiltersProps {
  minPossibleDistance?: number;
  maxPossibleDistance?: number;
  minPossibleElevationGain?: number;
  maxPossibleElevationGain?: number;
  type?: CyclingRouteType;
  onApply: (opts: RoutesFilterState) => void;
  initialState?: RoutesFilterState;
}
export const RoutesFilters: React.FC<RoutesFiltersProps> = ({
  minPossibleDistance = 0,
  maxPossibleDistance = 500000,
  minPossibleElevationGain = 0,
  maxPossibleElevationGain = 10000,
  onApply,
  initialState,
}) => {
  const [state, setState] = useState<RoutesFilterState>(
    initialState ? initialState : defaultRoutesFilterState,
  );

  const toggleType = (type: CyclingRouteType) => {
    if (state.types.includes(type)) {
      const index = state.types.indexOf(type);
      const types = [...state.types];

      types.splice(index, 1);

      setState({ ...state, types: types });
      return;
    }

    const types = [...state.types, type];
    setState({ ...state, types: types });
  };

  const handleSliderOnChange = (
    sliderName: 'minDistance' | 'maxDistance' | 'minElevationGain' | 'maxElevationGain',
    value: number,
  ) => {
    const newState = { ...state };
    newState[sliderName] = value;

    setState(newState);
  };

  return (
    <Box fontSize="xs" borderWidth="1px" p={4} bg="white" borderRadius="md">
      <HStack marginBottom={5}>
        <FormLabel fontSize="xs">Type</FormLabel>
        <Spacer />
        <ToggleButton
          text="Road"
          isSelected={state.types.includes('road')}
          onToggle={() => toggleType('road')}
        />
        <ToggleButton
          text="Gravel"
          isSelected={state.types.includes('gravel')}
          onToggle={() => toggleType('gravel')}
        />
        <ToggleButton
          text="Mountain"
          isSelected={state.types.includes('mountain')}
          onToggle={() => toggleType('mountain')}
        />
      </HStack>

      <SliderFilter
        label="Min Distance"
        min={minPossibleDistance}
        max={maxPossibleDistance}
        defaultValue={state.minDistance}
        unitFormatter={DistanceFormatter}
        onChange={(val) => handleSliderOnChange('minDistance', val)}
        value={state.minDistance}
      />
      <SliderFilter
        label="Max Distance"
        min={minPossibleDistance}
        max={maxPossibleDistance}
        defaultValue={state.maxDistance}
        value={state.maxDistance}
        unitFormatter={DistanceFormatter}
        onChange={(value) => handleSliderOnChange('maxDistance', value)}
      />
      <SliderFilter
        label="Min Elevation Gain"
        min={minPossibleElevationGain}
        max={maxPossibleElevationGain}
        defaultValue={state.minElevationGain}
        value={state.minElevationGain}
        unitFormatter={AltitudeFormatter}
        onChange={(value) => handleSliderOnChange('minElevationGain', value)}
      />
      <SliderFilter
        label="Max Elevation Gain"
        min={minPossibleElevationGain}
        max={maxPossibleElevationGain}
        defaultValue={state.maxElevationGain}
        value={state.maxElevationGain}
        unitFormatter={AltitudeFormatter}
        onChange={(value) => handleSliderOnChange('maxElevationGain', value)}
      />

      <Button
        size="xs"
        colorScheme="primary"
        w="100%"
        marginTop={2}
        onClick={() => {
          onApply(state);
        }}
      >
        Apply
      </Button>
    </Box>
  );
};
