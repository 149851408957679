import React from 'react';
import { Box, CircularProgress, Heading, Text, VStack } from '@chakra-ui/react';

export const SyncModalWorking: React.FC = () => {
  return (
    <Box maxW="md">
      <VStack>
        <CircularProgress isIndeterminate color="primary.500" size="7rem" />
        <Heading as="h2" size="md">
          Working on it...
        </Heading>
        <Text color="gray.400" textAlign="center">
          Hang on! This might take a while, we will let you know when is finished.
        </Text>
      </VStack>
    </Box>
  );
};
