import { AthleteMeta, zeroAthleteMeta } from './model';
import axios from 'axios';
import { env } from '../../env';
import { Resource, wrapPromise } from '../api';

const fetch = function (p: {
  accessTokenFn: () => Promise<string>;
  athleteId: string;
}): Resource<AthleteMeta> {
  const athleteMetaPromise = p.accessTokenFn().then((token) => {
    return axios
      .request<AthleteMeta>({
        method: 'GET',
        baseURL: env.api,
        url: `/${p.athleteId}/meta`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        // we are going to merge the response with the zero athlete meta because some of the props of the athlete meta
        // are computed depending on the user interaction, so they are not necessarily in place when we receive it from
        // the API
        return { ...zeroAthleteMeta, ...response.data };
      });
  });

  return wrapPromise(athleteMetaPromise);
};

const athleteMetaService = {
  fetch: fetch,
};

export default athleteMetaService;
