import React from 'react';
import { Button, Tag, HStack, Spacer } from '@chakra-ui/react';

export interface Props {
  name: string;
  nRoutes: number;
  onClick: (name: string) => void;
}

export const TagItem: React.FC<Props> = function ({ name, nRoutes, onClick }) {
  return (
    <HStack w="100%">
      <Button onClick={() => onClick(name)} variant="ghost" size="sm" isTruncated alt={name}>
        {name}
      </Button>
      <Spacer />
      <Tag rounded="full">{nRoutes}</Tag>
    </HStack>
  );
};
