import React from 'react';
import { Box, IconButton, Tag, Spacer, HStack, Tooltip } from '@chakra-ui/react';
import { StaticRouteMap } from '../StaticRouteMap';
import { DistanceFormatter } from '../DistanceFormatter';
import { AltitudeFormatter } from '../AltitudeFormatter';
import theme from '../../theme';
import { CyclingRouteType } from '../../state/route/route';
import { DistanceIcon, ElevationIcon, PlusIcon, TagIcon } from '../Icons';
import { Link } from '@chakra-ui/react';
import { Link as RouterLink } from 'react-router-dom';

export interface Props {
  name: string;
  distance: number;
  elevationGain: number;
  routePolyline: string;
  width?: number;
  height?: number;
  type: CyclingRouteType;
  tags: string[];
  routeId: string;
  maxNumOfTagsToDisplay?: number;
}

export const RouteCard: React.FC<Props> = ({
  name,
  distance,
  elevationGain,
  routePolyline,
  width = 300,
  height,
  type,
  tags,
  routeId,
  maxNumOfTagsToDisplay = 20,
}) => {
  const tagsAlphabeticalOrder = tags
    .slice()
    .sort((a, b) => a.localeCompare(b))
    .slice(0, maxNumOfTagsToDisplay);

  const tagsLabel = tagsAlphabeticalOrder.map((tag) => (
    <Box key={tag} fontSize="xs">
      #{tag}
    </Box>
  ));

  if (tags.length > maxNumOfTagsToDisplay) {
    tagsLabel.push(
      <Box mt={2} fontSize="xs">
        more...
      </Box>,
    );
  }

  return (
    //TODO how to make the width dynamic?
    <Box maxW="sm" borderWidth="1px" borderRadius="md" overflow="hidden" w={width} pos="relative">
      <StaticRouteMap
        routePolyline={routePolyline}
        width={width}
        height={height}
        lineColour={theme.colors.map.defaultHexWithoutHash}
      />

      <Box p="3" bg="white">
        <HStack pb={2}>
          <Box
            d="flex"
            alignItems="center"
            color="gray.500"
            fontWeight="semibold"
            letterSpacing="wide"
            fontSize="xs"
            mr={1}
          >
            <DistanceIcon mr={1} />
            <DistanceFormatter value={distance} precision={0} />
          </Box>

          <Box
            d="flex"
            alignItems="center"
            color="gray.500"
            fontWeight="semibold"
            letterSpacing="wide"
            fontSize="xs"
            mr={1}
          >
            <ElevationIcon mr={1} />
            <AltitudeFormatter value={elevationGain} />
          </Box>

          <Box
            d="flex"
            alignItems="center"
            color="gray.500"
            fontWeight="semibold"
            letterSpacing="wide"
            fontSize="xs"
            textTransform="uppercase"
            mr={1}
          >
            {tags.length > 0 ? (
              <Tooltip label={tagsLabel} hasArrow>
                <Box>
                  <TagIcon mr={1} />
                  {tags.length}
                </Box>
              </Tooltip>
            ) : (
              <Box>
                <TagIcon mr={1} />0
              </Box>
            )}
          </Box>
          <Spacer />

          <Tag fontSize="xs" size="sm" borderRadius="full">
            {type}
          </Tag>
        </HStack>

        <Box mt="1" fontWeight="semibold" isTruncated textAlign="left">
          <Link as={RouterLink} to={`/route/${routeId}`}>
            {name}
          </Link>
        </Box>

        <IconButton
          size="xs"
          variant="ghost"
          colorScheme="primary"
          icon={<PlusIcon />}
          pos="absolute"
          right={3}
          top={3}
          aria-label="Add to comparison list"
          alt="Add route to the comparison list"
        >
          compare
        </IconButton>
      </Box>
    </Box>
  );
};
