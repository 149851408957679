import React from 'react';
import { Resource } from '../../state/api';
import { AthleteMeta } from '../../state/athlete-meta/model';
import { Item, TagsAutoComplete } from './index';

export interface Props {
  athleteMetaResource: Resource<AthleteMeta>;
  onCreateItem: (item: Item) => void;
  selectedItems: Item[];
  onSelectedItemsChange: (selectedItems?: Item[]) => void;
}

export const TagsAutoCompleteWithResources: React.FC<Props> = function ({
  athleteMetaResource,
  selectedItems,
  onCreateItem,
  onSelectedItemsChange,
}) {
  const athleteMeta = athleteMetaResource.read();
  const items = Object.keys(athleteMeta.tagsCount).map((tag) => {
    return {
      label: tag,
      value: tag,
    };
  });

  return (
    <TagsAutoComplete
      items={items}
      label={'Tags'}
      placeholder={'add tags...'}
      selectedItems={selectedItems}
      onCreateItem={onCreateItem}
      onSelectedItemsChange={(changes) => onSelectedItemsChange(changes.selectedItems)}
      inputStyleProps={{
        size: 'sm',
        borderRadius: 'lg',
      }}
      toggleButtonStyleProps={{
        size: 'sm',
      }}
    />
  );
};
