import React from 'react';
import theme from '../../theme';

export interface StaticRouteMapProps {
  routePolyline: string;
  width?: number;
  height?: number;
  lineColour?: string;
  lineWidth?: number;
}

export const StaticRouteMap: React.FC<StaticRouteMapProps> = ({
  routePolyline,
  height = 175,
  width = 291,
  lineColour = theme.colors.map.defaultHexWithoutHash,
  lineWidth = 5,
}) => {
  const encoded = encodeURIComponent(routePolyline);

  return (
    <div>
      <img
        src={`https://api.mapbox.com/styles/v1/mapbox/light-v10/static/path-${lineWidth}+${lineColour}-0.6(${encoded})/auto/${width}x${height}?access_token=${process.env.REACT_APP_MAPBOX_ACCESS_TOKEN}`}
        alt="Route Map"
        width={width}
        height={height}
      />
    </div>
  );
};
