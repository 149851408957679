import React, { MouseEventHandler } from 'react';
import { Button, Box } from '@chakra-ui/react';

export interface ToggleButtonProps {
  text: string;
  isSelected: boolean;
  onToggle: MouseEventHandler;
}

export const ToggleButton: React.FC<ToggleButtonProps> = ({ text, isSelected, onToggle }) => {
  return (
    <Box>
      {isSelected ? (
        <Button onClick={onToggle} colorScheme="primary" size="xs">
          {text}
        </Button>
      ) : (
        <Button onClick={onToggle} size="xs">
          {text}
        </Button>
      )}
    </Box>
  );
};
