import React, { useEffect, useState, Suspense } from 'react';
import { VStack, Tabs, TabList, Tab, TabPanels, TabPanel } from '@chakra-ui/react';
import { useParams, useHistory } from 'react-router-dom';

import { GridIcon, MapIcon, SyncIcon, TagIcon } from '../../components/Icons';
import GridTab from './grid';
import { MapTab } from './map';
import { TagsTab } from './tag';
import { ErrorBoundary } from 'react-error-boundary';
import { ErrorSwitcher } from '../../components/Errors/switcher';
import { useAuth0 } from '@auth0/auth0-react';
import { getStravaIdFromSub } from '../../auth/utils';
import { Resource } from '../../state/api';
import { AthleteMeta } from '../../state/athlete-meta/model';
import athleteMetaService from '../../state/athlete-meta/service';
import LastSyncTab from './last-sync';
import { Spinner } from '../../components/Spinner';

interface PathParams {
  urlTab: 'grid' | 'map' | 'tags' | 'last-sync';
}

const exploreTabs = ['grid', 'map', 'tags', 'last-sync'];

const errorFallbackRenderer = function (p: { error: Error }) {
  return <ErrorSwitcher error={p.error} />;
};

export const ExplorePage: React.FC = function () {
  const { getAccessTokenSilently, user } = useAuth0();
  const athleteId = getStravaIdFromSub(user?.sub);
  const { urlTab } = useParams<PathParams>();
  const selectedTabIndex = exploreTabs.indexOf(urlTab);
  const history = useHistory();
  const [athleteMetaResource, setAthleteMetaResource] = useState<Resource<AthleteMeta>>();

  useEffect(() => {
    const resource = athleteMetaService.fetch({
      accessTokenFn: getAccessTokenSilently,
      athleteId: athleteId,
    });

    setAthleteMetaResource(resource);
  }, []);

  const handleTabsChange = function (index: number) {
    history.push(`/explore/${exploreTabs[index]}`);
  };

  if (!athleteMetaResource) {
    return null; //TODO
  }

  return (
    <>
      <VStack w="100%">
        <Tabs
          align="center"
          colorScheme="primary"
          w="100%"
          isLazy
          index={selectedTabIndex}
          onChange={handleTabsChange}
        >
          <TabList>
            <Tab>
              <GridIcon mr={2} />
              Grid
            </Tab>
            <Tab>
              <MapIcon mr={2} /> Map
            </Tab>
            <Tab>
              <TagIcon mr={2} /> Tags
            </Tab>
            <Tab>
              <SyncIcon mr={2} />
              Last sync
            </Tab>
          </TabList>

          <TabPanels>
            <TabPanel>
              <ErrorBoundary fallbackRender={errorFallbackRenderer}>
                <GridTab />
              </ErrorBoundary>
            </TabPanel>
            <TabPanel>
              <ErrorBoundary fallbackRender={errorFallbackRenderer}>
                <Suspense fallback={<Spinner verticalPadding={16} />}>
                  <MapTab />
                </Suspense>
              </ErrorBoundary>
            </TabPanel>
            <TabPanel>
              <ErrorBoundary fallbackRender={errorFallbackRenderer}>
                <Suspense fallback={<Spinner verticalPadding={16} />}>
                  <TagsTab athleteMetaResource={athleteMetaResource} />
                </Suspense>
              </ErrorBoundary>
            </TabPanel>
            <TabPanel>
              <ErrorBoundary fallbackRender={errorFallbackRenderer}>
                <LastSyncTab athleteMetaResource={athleteMetaResource} />
              </ErrorBoundary>
            </TabPanel>
          </TabPanels>
        </Tabs>
      </VStack>
    </>
  );
};

export default ExplorePage;
