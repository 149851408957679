import React from 'react';
import { Box, VStack, Text } from '@chakra-ui/react';
import { SyncModalWithContext } from '../../context/SyncModalWithContext';
import { NotFoundIcon } from '../Icons';

export const NoRoutes: React.FC = () => {
  return (
    <Box p={12}>
      <VStack spacing={8}>
        <NotFoundIcon boxSize={24} color="gray.300" />
        <Box>
          <Text>
            No route found. If you are searching for a particular route, try changing the filter
            parameters.
          </Text>
          <Text>You can also try to synchronise your routes again with Strava</Text>
        </Box>
        <SyncModalWithContext />
      </VStack>
    </Box>
  );
};
