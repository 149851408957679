import { atom } from 'recoil';
import { CyclingRouteType } from './route';

export interface RoutesFilterState {
  minDistance: number;
  maxDistance: number;
  minElevationGain: number;
  maxElevationGain: number;
  types: CyclingRouteType[];
}

export const defaultRoutesFilterState: RoutesFilterState = {
  minDistance: 0,
  maxDistance: 150000,
  minElevationGain: 0,
  maxElevationGain: 2000,
  types: [],
};

export const maxRoutesFiltersState: RoutesFilterState = {
  minDistance: 0,
  maxDistance: 500000,
  minElevationGain: 0,
  maxElevationGain: 10000,
  types: [],
};

export const routesFilterState = atom({
  key: 'routes-filter-state',
  default: defaultRoutesFilterState,
});
