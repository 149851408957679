import React, { useEffect, useState } from 'react';
import { Point, ResponsiveLineCanvas } from '@nivo/line';
import { Box } from '@chakra-ui/react';
import { meters } from '../../types';
import { formatDistance } from '../DistanceFormatter';
import { formatAltitude } from '../AltitudeFormatter';
import theme from '../../theme';

export type CyclingRouteStreamPoint = {
  c: [number, number]; // coordinate [lat, lon]
  d: meters; // distance
  a: meters; // altitude
};

export interface Props {
  stream: CyclingRouteStreamPoint[];
  width?: number;
  height?: number;
  nTicksBottom?: number;
  nTicksLeft?: number;
}

export const ElevationChart: React.FC<Props> = ({
  stream,
  width = 350,
  height = 100,
  nTicksBottom = 8,
  nTicksLeft = 5,
}) => {
  const [margin, setMargin] = useState<[number, number, number, number]>([10, 10, 30, 50]);
  const [bottomTicks, setBottomTicks] = useState<number[]>([]);
  const [leftTicks, setLeftTicks] = useState<number[]>([]);
  const [altMinMax, setAltMinMax] = useState<[meters, meters]>([0, 0]);

  useEffect(() => {
    // Maximum altitude
    const altitudeStream: number[] = stream.map((point) => point.a);
    const altitudeMax = Math.max(...altitudeStream);
    const altitudeMin = Math.min(...altitudeStream);

    setAltMinMax([altitudeMin, altitudeMax]);

    // Bottom ticks
    const distance = stream[stream.length - 1].d;
    const stepBottom = Math.floor(distance / nTicksBottom);
    let bTicks: number[] = [];

    for (let i = 0; i < nTicksBottom; i++) {
      bTicks.push(stepBottom * i);
    }

    setBottomTicks(bTicks);

    // Left ticks
    const stepLeft = Math.floor((altitudeMax - altitudeMin) / nTicksLeft);
    let lTicks: number[] = [];

    for (let i = 0; i < nTicksLeft; i++) {
      lTicks.push(altitudeMin + stepLeft * i);
    }

    setLeftTicks(lTicks);
    // Left axis margin
    if (altitudeMax > 1000 && altitudeMax < 10000) {
      setMargin([10, 10, 50, 50]);
    }
  }, [stream, nTicksLeft, nTicksBottom]);

  const data = stream.map((point) => {
    return {
      x: point.d,
      y: point.a,
    };
  });

  const series = [
    {
      id: 'altitude',
      data: data,
    },
  ];

  return (
    <Box w={width} h={height}>
      <ResponsiveLineCanvas
        data={series}
        colors={[theme.colors.gray[400]]}
        areaOpacity={0.3}
        enablePoints={false}
        enableArea={true}
        enableGridY={true}
        enableGridX={true}
        areaBaselineValue={altMinMax[0]}
        gridXValues={bottomTicks}
        gridYValues={leftTicks}
        lineWidth={1}
        margin={{ top: margin[0], right: margin[1], bottom: margin[2], left: margin[3] }}
        axisBottom={{
          tickValues: bottomTicks,
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          format: (val) => formatDistance(val as number),
        }}
        axisLeft={{
          tickValues: leftTicks,
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          format: (val) => formatAltitude(val as number),
        }}
        xScale={{ type: 'linear' }}
        xFormat={(val) => formatDistance(val as number)}
        yScale={{ type: 'linear', min: altMinMax[0], max: altMinMax[1] }}
        yFormat={(val) => formatAltitude(val as number)}
        theme={{
          textColor: theme.colors.gray[500],
          axis: {
            ticks: {
              text: {
                color: '#bbeecc',
              },
            },
          },
          grid: {
            line: {
              strokeWidth: 0.6,
            },
          },
        }}
        tooltip={({ point }: { point: Point }) => (
          <Box bg="white" borderWidth="1px" borderRadius="md" p={2}>
            {point.data.xFormatted} <Box>{point.data.yFormatted}</Box>
          </Box>
        )}
      />
    </Box>
  );
};
