import React from 'react';
import { Grid, VStack } from '@chakra-ui/react';
import { RouteCard } from '../RouteCard';
import { CyclingRouteLight } from '../../state/route/route';
import { NoRoutes } from './no-routes';

export interface Props {
  routes: CyclingRouteLight[];
  numOfColumns?: number;
  cardWidth?: number;
}

export const RoutesGrid: React.FC<Props> = ({ routes, numOfColumns = 3, cardWidth = 300 }) => {
  if (routes.length === 0) {
    return <NoRoutes />;
  }

  return (
    <VStack>
      <Grid templateColumns={`repeat(${numOfColumns}, 1fr)`} gridGap={5}>
        {routes.map((route) => {
          return (
            <RouteCard
              name={route.strava.name}
              distance={route.distance}
              elevationGain={route.elevationGain}
              routePolyline={route.summaryPolyline}
              key={route._id}
              type={route.type}
              tags={route.tags}
              routeId={route._id}
              width={cardWidth}
            />
          );
        })}
      </Grid>
    </VStack>
  );
};
