import React from 'react';
import { VStack } from '@chakra-ui/react';
import { CollectionRes, Resource } from '../../state/api';
import { CyclingRouteLight } from '../../state/route/route';
import { NoRoutes } from './no-routes';
import { RoutesGrid } from './index';
import { usePagination } from '../../hooks/pagination';
import { GridPaginator } from '../GridPaginator';
interface Props {
  resource: Resource<CollectionRes<CyclingRouteLight>>;
  onGoToPage: (cursor: string) => void;
  numOfColumns?: number;
  cardWidth?: number;
}

export const RoutesGridWithResource: React.FC<Props> = function ({
  resource,
  onGoToPage,
  numOfColumns,
  cardWidth,
}) {
  const {
    getCursorForPreviousPage,
    getCursorForNextPage,
    getCursorForPage,
    hasMultiplePages,
    hasMoreItems,
    currentPageNumber,
    isCurrentPageLast,
    isCurrentPageFirst,
    pagesIndexes,
    handleNewPageResponse,
  } = usePagination();

  const routes = resource.read();

  handleNewPageResponse(routes);

  if (routes.items.length === 0) {
    return <NoRoutes />;
  }

  const handleOnGoToPreviousPage = function () {
    onGoToPage(getCursorForPreviousPage());
  };

  const handleOnGoToNextPage = function () {
    onGoToPage(getCursorForNextPage());
  };

  const handleOnJumpToPage = function (pageNumber: number) {
    onGoToPage(getCursorForPage(pageNumber));
  };

  return (
    <VStack>
      <RoutesGrid routes={routes.items} numOfColumns={numOfColumns} cardWidth={cardWidth} />
      {hasMultiplePages ? (
        <GridPaginator
          currentPage={currentPageNumber}
          hasMoreItems={hasMoreItems}
          isCurrentPageFirst={isCurrentPageFirst}
          isCurrentPageLast={isCurrentPageLast}
          onGoNext={handleOnGoToNextPage}
          onGoPrevious={handleOnGoToPreviousPage}
          onJumpToPage={handleOnJumpToPage}
          pagesAvailable={pagesIndexes}
        />
      ) : null}
    </VStack>
  );
};
