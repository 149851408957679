import React, { useEffect, useState, Suspense } from 'react';
import { Grid } from '@chakra-ui/react';
import { CyclingRouteLight } from '../../state/route/route';
import routesService from '../../state/route/service';
import { useAuth0 } from '@auth0/auth0-react';
import { getStravaIdFromSub } from '../../auth/utils';
import { RoutesGridWithResource } from '../../components/RoutesGrid/with-resource';
import { CollectionRes, Resource } from '../../state/api';
import { AthleteMeta } from '../../state/athlete-meta/model';
import { RoutesGridFallback } from '../../components/RoutesGrid/fallback';
import { Helmet } from 'react-helmet-async';

interface Props {
  athleteMetaResource: Resource<AthleteMeta>;
}

export const LastSyncTab: React.FC<Props> = function ({ athleteMetaResource }) {
  const { getAccessTokenSilently, user } = useAuth0();
  const athleteId = getStravaIdFromSub(user?.sub);
  const athleteMeta = athleteMetaResource.read();
  const [routesResource, setRoutesResource] = useState<
    Resource<CollectionRes<CyclingRouteLight>>
  >();

  useEffect(() => {
    const resource = routesService.fetchMany({
      accessTokenFn: getAccessTokenSilently,
      athleteId: athleteId,
      types: [],
      syncedAt: athleteMeta.lastSyncAt,
    });

    setRoutesResource(resource);
  }, []);

  const handleOnPageChange = function (cursor: string) {
    const resource = routesService.fetchMany({
      accessTokenFn: getAccessTokenSilently,
      athleteId: athleteId,
      types: [],
      cursor: cursor,
    });

    setRoutesResource(resource);
  };

  return (
    <>
      <Helmet>
        <title>Explore | Last Synchronised</title>
      </Helmet>
      <Grid templateColumns="1fr 4fr" gap={4}>
        {routesResource ? (
          <Suspense fallback={<RoutesGridFallback numColumns={4} numCards={8} />}>
            <RoutesGridWithResource
              resource={routesResource}
              onGoToPage={handleOnPageChange}
              numOfColumns={4}
              cardWidth={290}
            />
          </Suspense>
        ) : null}
      </Grid>
    </>
  );
};

export default LastSyncTab;
