import { createIcon } from '@chakra-ui/react';

export const TagIcon = createIcon({
  displayName: 'TagIcon',
  viewBox: '0 0 512 512',
  d:
    'M497.941 225.941L286.059 14.059A48 48 0 0 0 252.118 0H48C21.49 0 0 21.49 0 48v204.118a47.998 47.998 0 0 0 14.059 33.941l211.882 211.882c18.745 18.745 49.137 18.746 67.882 0l204.118-204.118c18.745-18.745 18.745-49.137 0-67.882zM259.886 463.996L48 252.118V48h204.118L464 259.882 259.886 463.996zM192 144c0 26.51-21.49 48-48 48s-48-21.49-48-48 21.49-48 48-48 48 21.49 48 48z',
});

export const ElevationIcon = createIcon({
  displayName: 'ElevationIcon',
  viewBox: '0 0 320 512',
  d:
    'M273.1 347.4c-4.8-4.8-12.5-4.7-17.1.2l-70 73.2V91.2l70 73.2c4.7 4.8 12.4 4.9 17.1.2l19.6-19.6c4.7-4.7 4.7-12.3 0-17L168.5 3.5c-4.7-4.7-12.3-4.7-17 0L27.2 128c-4.7 4.7-4.7 12.3 0 17l19.6 19.6c4.8 4.8 12.5 4.7 17.1-.2l70-73.2v329.6l-70-73.2c-4.7-4.8-12.4-4.9-17.1-.2L27.2 367c-4.7 4.7-4.7 12.3 0 17l124.3 124.5c4.7 4.7 12.3 4.7 17 0L292.8 384c4.7-4.7 4.7-12.3 0-17l-19.7-19.6z',
});

export const DistanceIcon = createIcon({
  displayName: 'DistanceIcon',
  viewBox: '0 0 512 512',
  d:
    'M347.404 142.86c-4.753 4.753-4.675 12.484.173 17.14l73.203 70H91.22l73.203-70c4.849-4.656 4.927-12.387.173-17.14l-19.626-19.626c-4.686-4.686-12.284-4.686-16.971 0L3.515 247.515c-4.686 4.686-4.686 12.284 0 16.971L128 388.766c4.686 4.686 12.284 4.686 16.971 0l19.626-19.626c4.753-4.753 4.675-12.484-.173-17.14L91.22 282h329.56l-73.203 70c-4.849 4.656-4.927 12.387-.173 17.14l19.626 19.626c4.686 4.686 12.284 4.686 16.971 0l124.485-124.281c4.686-4.686 4.686-12.284 0-16.971L384 123.234c-4.686-4.686-12.284-4.686-16.971 0l-19.625 19.626z',
});

export const EstimatedTimeIcon = createIcon({
  displayName: 'EstimatedTimeIcon',
  viewBox: '0 0 512 512',
  d:
    'M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm0 448c-110.5 0-200-89.5-200-200S145.5 56 256 56s200 89.5 200 200-89.5 200-200 200zm61.8-104.4l-84.9-61.7c-3.1-2.3-4.9-5.9-4.9-9.7V116c0-6.6 5.4-12 12-12h32c6.6 0 12 5.4 12 12v141.7l66.8 48.6c5.4 3.9 6.5 11.4 2.6 16.8L334.6 349c-3.9 5.3-11.4 6.5-16.8 2.6z',
});

export const PlusIcon = createIcon({
  displayName: 'PlusIcon',
  viewBox: '0 0 384 512',
  d:
    'M368 224H224V80c0-8.84-7.16-16-16-16h-32c-8.84 0-16 7.16-16 16v144H16c-8.84 0-16 7.16-16 16v32c0 8.84 7.16 16 16 16h144v144c0 8.84 7.16 16 16 16h32c8.84 0 16-7.16 16-16V288h144c8.84 0 16-7.16 16-16v-32c0-8.84-7.16-16-16-16z',
});

export const LocationIcon = createIcon({
  displayName: 'LocationIcon',
  viewBox: '0 0 512 512',
  d:
    'M444.52 3.52L28.74 195.42c-47.97 22.39-31.98 92.75 19.19 92.75h175.91v175.91c0 51.17 70.36 67.17 92.75 19.19l191.9-415.78c15.99-38.39-25.59-79.97-63.97-63.97z',
});

export const ExploreIcon = createIcon({
  displayName: 'ExploreIcon',
  viewBox: '0 0 512 512',
  d:
    'M508.5 468.9L387.1 347.5c-2.3-2.3-5.3-3.5-8.5-3.5h-13.2c31.5-36.5 50.6-84 50.6-136C416 93.1 322.9 0 208 0S0 93.1 0 208s93.1 208 208 208c52 0 99.5-19.1 136-50.6v13.2c0 3.2 1.3 6.2 3.5 8.5l121.4 121.4c4.7 4.7 12.3 4.7 17 0l22.6-22.6c4.7-4.7 4.7-12.3 0-17zM208 368c-88.4 0-160-71.6-160-160S119.6 48 208 48s160 71.6 160 160-71.6 160-160 160z',
});

export const SyncIcon = createIcon({
  displayName: 'SyncIcon',
  viewBox: '0 0 512 512',
  d:
    'M500 8h-27.711c-6.739 0-12.157 5.548-11.997 12.286l2.347 98.575C418.212 52.043 342.256 8 256 8 134.813 8 33.933 94.924 12.296 209.824 10.908 217.193 16.604 224 24.103 224h28.576c5.674 0 10.542-3.982 11.737-9.529C83.441 126.128 161.917 60 256 60c79.545 0 147.942 47.282 178.676 115.302l-126.39-3.009c-6.737-.16-12.286 5.257-12.286 11.997V212c0 6.627 5.373 12 12 12h192c6.627 0 12-5.373 12-12V20c0-6.627-5.373-12-12-12zm-12.103 280h-28.576c-5.674 0-10.542 3.982-11.737 9.529C428.559 385.872 350.083 452 256 452c-79.546 0-147.942-47.282-178.676-115.302l126.39 3.009c6.737.16 12.286-5.257 12.286-11.997V300c0-6.627-5.373-12-12-12H12c-6.627 0-12 5.373-12 12v192c0 6.627 5.373 12 12 12h27.711c6.739 0 12.157-5.548 11.997-12.286l-2.347-98.575C93.788 459.957 169.744 504 256 504c121.187 0 222.067-86.924 243.704-201.824 1.388-7.369-4.308-14.176-11.807-14.176z',
});

export const GridIcon = createIcon({
  displayName: 'GridIcon',
  viewBox: '0 0 512 512',
  d:
    'M464 32H48C21.5 32 0 53.5 0 80v352c0 26.5 21.5 48 48 48h416c26.5 0 48-21.5 48-48V80c0-26.5-21.5-48-48-48zM197.3 72h117.3v96H197.3zm0 136h117.3v96H197.3zm-40 232H52c-6.6 0-12-5.4-12-12v-84h117.3zm0-136H40v-96h117.3zm0-136H40V84c0-6.6 5.4-12 12-12h105.3zm157.4 272H197.3v-96h117.3v96zm157.3 0H354.7v-96H472zm0-136H354.7v-96H472zm0-136H354.7V72H472z',
});

export const MapIcon = createIcon({
  displayName: 'MapIcon',
  viewBox: '0 0 576 512',
  d:
    'M560 160c-2 0-4 .4-6 1.2l-73.5 27.2c-8.2 20.4-20.2 42-34.2 63.8L528 222v193l-128 44.5V316.3c-13.7 17.3-27.9 34.3-42.5 50.8-1.7 1.9-3.6 3.5-5.5 5.1v81.4l-128-45.2v-113c-18.1-24.1-34.8-48.8-48-72.8v180.2l-.6.2L48 450V257l123.6-43c-8-15.4-14.1-30.3-18.3-44.5L20.1 216C8 220.8 0 232.6 0 245.7V496c0 9.2 7.5 16 16 16 2 0 4-.4 6-1.2L192 448l172 60.7c13 4.3 27 4.4 40 .2L555.9 456c12.2-4.9 20.1-16.6 20.1-29.7V176c0-9.2-7.5-16-16-16zM320 352c5 0 10-2 13.5-6.1 35.3-40 127.3-150.1 127.3-210.6C460.8 60.6 397.8 0 320 0S179.2 60.6 179.2 135.3c0 60.4 92 170.6 127.3 210.6C310 350 315 352 320 352zm0-304c51.2 0 92.8 39.2 92.8 87.3 0 21.4-31.8 79.1-92.8 152.6-61-73.5-92.8-131.2-92.8-152.6 0-48.1 41.6-87.3 92.8-87.3z',
});

export const MapMarker = createIcon({
  displayName: 'MapMarker',
  viewBox: '0 0 384 512',
  d:
    'M172.268 501.67C26.97 291.031 0 269.413 0 192 0 85.961 85.961 0 192 0s192 85.961 192 192c0 77.413-26.97 99.031-172.268 309.67-9.535 13.774-29.93 13.773-39.464 0z',
});

export const StarOutlineIcon = createIcon({
  displayName: 'StarOutlineIcon',
  viewBox: '0 0 576 512',
  d:
    'M528.1 171.5L382 150.2 316.7 17.8c-11.7-23.6-45.6-23.9-57.4 0L194 150.2 47.9 171.5c-26.2 3.8-36.7 36.1-17.7 54.6l105.7 103-25 145.5c-4.5 26.3 23.2 46 46.4 33.7L288 439.6l130.7 68.7c23.2 12.2 50.9-7.4 46.4-33.7l-25-145.5 105.7-103c19-18.5 8.5-50.8-17.7-54.6zM388.6 312.3l23.7 138.4L288 385.4l-124.3 65.3 23.7-138.4-100.6-98 139-20.2 62.2-126 62.2 126 139 20.2-100.6 98z',
});

export const StarSolidIcon = createIcon({
  displayName: 'StarSolidIcon',
  viewBox: '0 0 576 512',
  d:
    'M259.3 17.8L194 150.2 47.9 171.5c-26.2 3.8-36.7 36.1-17.7 54.6l105.7 103-25 145.5c-4.5 26.3 23.2 46 46.4 33.7L288 439.6l130.7 68.7c23.2 12.2 50.9-7.4 46.4-33.7l-25-145.5 105.7-103c19-18.5 8.5-50.8-17.7-54.6L382 150.2 316.7 17.8c-11.7-23.6-45.6-23.9-57.4 0z',
});

export const StartIcon = createIcon({
  displayName: 'StartIcon',
  viewBox: '0 0 496 512',
  d:
    'M248 56c110.5 0 200 89.5 200 200s-89.5 200-200 200S48 366.5 48 256 137.5 56 248 56m0-48C111 8 0 119 0 256s111 248 248 248 248-111 248-248S385 8 248 8zm0 184c-35.3 0-64 28.7-64 64s28.7 64 64 64 64-28.7 64-64-28.7-64-64-64z',
});

export const FinishIcon = createIcon({
  displayName: 'FinishIcon',
  viewBox: '0 0 496 512',
  d:
    'M248 8C111 8 0 119 0 256s111 248 248 248 248-111 248-248S385 8 248 8zm0 312c-35.3 0-64-28.7-64-64s28.7-64 64-64 64 28.7 64 64-28.7 64-64 64z',
});

export const CurrentIcon = createIcon({
  displayName: 'CurrentIcon',
  viewBox: '0 0 496 512',
  d:
    'M256 56c110.532 0 200 89.451 200 200 0 110.532-89.451 200-200 200-110.532 0-200-89.451-200-200 0-110.532 89.451-200 200-200m0-48C119.033 8 8 119.033 8 256s111.033 248 248 248 248-111.033 248-248S392.967 8 256 8zm0 168c-44.183 0-80 35.817-80 80s35.817 80 80 80 80-35.817 80-80-35.817-80-80-80z',
});

export const BrokenIcon = createIcon({
  displayName: 'BrokenIcon',
  viewBox: '0 0 512 512',
  d:
    'M343.7 352H168.3c-5.8 0-9.8 5.7-7.8 11 10.5 27.9 58.4 53 95.5 53s85-25.1 95.5-53c2-5.3-2-11-7.8-11zM192 320c17.7 0 32-14.3 32-32s-14.3-32-32-32-32 14.3-32 32 14.3 32 32 32zm128-64c-17.7 0-32 14.3-32 32s14.3 32 32 32 32-14.3 32-32-14.3-32-32-32zm161.8 75.1c2.8-9.5 4.2-19.5 4.2-29.6 0-38.9-21-73-52.2-91.6.1-2 .2-3.9.2-5.9 0-37.9-21.2-71-52.4-87.9C376.5 51.2 322.1 0 256 0c-7.6 0-14.2.9-19.8 1.8-16.4 2.6-30.4 13.6-36.8 28.9-6.4 15.4-4.4 33 5.3 46.5 2.2 3 3.3 6.3 3.3 9.8 0 9.4-7.6 17-17 17h-13c-55.1 0-100 44.9-100 100 0 2 .1 4 .2 5.9C47 228.5 26 262.6 26 301.5c0 10.2 1.4 20.2 4.2 29.6C11.4 350.4 0 376.7 0 405.5 0 464.2 47.8 512 106.5 512h299c58.7 0 106.5-47.8 106.5-106.5 0-28.8-11.4-55.1-30.2-74.4zM405.5 464h-299C74.2 464 48 437.8 48 405.5c0-29.1 21.4-53.1 49.3-57.6C83.2 337.2 74 320.5 74 301.5c0-32.3 26.2-58.5 58.5-58.5h11.4c-10.9-9.5-17.9-23.4-17.9-39 0-28.7 23.3-52 52-52h13c35.9 0 65-29.1 65-65 0-14.1-4.6-27.1-12.3-37.8 4-.6 8.1-1.2 12.3-1.2 43.1 0 78 34.9 78 78 0 9.2-1.9 17.8-4.8 26h4.8c28.7 0 52 23.3 52 52 0 15.6-7 29.5-17.9 39h11.4c32.3 0 58.5 26.2 58.5 58.5 0 19-9.2 35.7-23.3 46.4 27.9 4.5 49.3 28.4 49.3 57.6 0 32.3-26.2 58.5-58.5 58.5z',
});

export const ForbiddenIcon = createIcon({
  displayName: 'ForbiddenIcon',
  viewBox: '0 0 512 512',
  d:
    'M256 8C119.034 8 8 119.033 8 256s111.034 248 248 248 248-111.034 248-248S392.967 8 256 8zm130.108 117.892c65.448 65.448 70 165.481 20.677 235.637L150.47 105.216c70.204-49.356 170.226-44.735 235.638 20.676zM125.892 386.108c-65.448-65.448-70-165.481-20.677-235.637L361.53 406.784c-70.203 49.356-170.226 44.736-235.638-20.676z',
});

export const NotFoundIcon = createIcon({
  displayName: 'NotFoundIcon',
  viewBox: '0 0 384 512',
  d:
    'M192 0c-1.96 0-3.93.03-5.91.09C81.01 3.24 0 94.92 0 200.05v263.92C0 473.61 7.89 480 16.12 480c3.93 0 7.94-1.46 11.2-4.72l24.92-18.53c2.86-2.12 6.21-3.16 9.54-3.16 4.43 0 8.82 1.83 11.97 5.38l42.95 48.35c3.12 3.12 7.22 4.69 11.31 4.69s8.19-1.56 11.31-4.69l40.72-45.85c3.18-3.58 7.57-5.38 11.96-5.38s8.78 1.79 11.96 5.38l40.72 45.85c3.12 3.12 7.22 4.69 11.31 4.69s8.19-1.56 11.31-4.69l42.95-48.35a15.994 15.994 0 0 1 21.51-2.22l24.92 18.53c3.26 3.26 7.27 4.72 11.2 4.72 8.22 0 16.12-6.39 16.12-16.03V192C384 85.96 298.04 0 192 0zm144 407.07c-4.48-.98-9.09-1.48-13.77-1.48-18.28 0-35.72 7.83-47.86 21.5L256 447.77l-16.15-18.18c-12.13-13.66-29.58-21.5-47.85-21.5s-35.71 7.84-47.85 21.5L128 447.77l-18.38-20.69a64.069 64.069 0 0 0-47.86-21.49c-4.68 0-9.29.5-13.77 1.48V200.05c0-81.49 62.6-149.67 139.53-151.98L192 48c79.4 0 144 64.6 144 144v215.07zM128 160c-17.67 0-32 14.33-32 32s14.33 32 32 32 32-14.33 32-32-14.33-32-32-32zm128 0c-17.67 0-32 14.33-32 32s14.33 32 32 32 32-14.33 32-32-14.33-32-32-32z',
});

export const RouteIcon = createIcon({
  displayName: 'RouteIcon',
  viewBox: '0 0 512 512',
  d:
    'M424 336h-96c-22.1 0-40-17.9-40-40s17.9-40 40-40h88s96-107 96-160-43-96-96-96-96 43-96 96c0 29.8 30.3 76.7 56.9 112H328c-48.5 0-88 39.5-88 88s39.5 88 88 88h96c22.1 0 40 17.9 40 40s-17.9 40-40 40H135.1c26.6-35.3 56.9-82.2 56.9-112 0-53-43-96-96-96S0 299 0 352s96 160 96 160h328c48.5 0 88-39.5 88-88s-39.5-88-88-88zM368 96c0-26.5 21.5-48 48-48s48 21.5 48 47.9c-.5 13.4-20.8 48.2-48 84.4-27.2-36.2-47.5-70.9-48-84.3zM96 436.3c-27.2-36.2-47.5-70.9-48-84.3 0-26.5 21.5-48 48-48s48 21.5 48 47.9c-.5 13.4-20.8 48.2-48 84.4zM96 336c-8.8 0-16 7.2-16 16s7.2 16 16 16 16-7.2 16-16-7.2-16-16-16zM432 96c0-8.8-7.2-16-16-16s-16 7.2-16 16 7.2 16 16 16 16-7.2 16-16z',
});

export const FiltersIcon = createIcon({
  displayName: 'FiltersIcon',
  viewBox: '0 0 512 512',
  d:
    'M496 72H288V48c0-8.8-7.2-16-16-16h-32c-8.8 0-16 7.2-16 16v24H16C7.2 72 0 79.2 0 88v16c0 8.8 7.2 16 16 16h208v24c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16v-24h208c8.8 0 16-7.2 16-16V88c0-8.8-7.2-16-16-16zm0 320H160v-24c0-8.8-7.2-16-16-16h-32c-8.8 0-16 7.2-16 16v24H16c-8.8 0-16 7.2-16 16v16c0 8.8 7.2 16 16 16h80v24c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16v-24h336c8.8 0 16-7.2 16-16v-16c0-8.8-7.2-16-16-16zm0-160h-80v-24c0-8.8-7.2-16-16-16h-32c-8.8 0-16 7.2-16 16v24H16c-8.8 0-16 7.2-16 16v16c0 8.8 7.2 16 16 16h336v24c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16v-24h80c8.8 0 16-7.2 16-16v-16c0-8.8-7.2-16-16-16z',
});

export const TagsIcon = createIcon({
  displayName: 'TagsIcon',
  viewBox: '0 0 640 512',
  d:
    'M625.941 293.823L421.823 497.941c-18.746 18.746-49.138 18.745-67.882 0l-.36-.36L592 259.882 331.397 0h48.721a48 48 0 0 1 33.941 14.059l211.882 211.882c18.745 18.745 18.745 49.137 0 67.882zm-128 0L293.823 497.941C284.451 507.314 272.166 512 259.882 512c-12.284 0-24.569-4.686-33.941-14.059L14.059 286.059A48 48 0 0 1 0 252.118V48C0 21.49 21.49 0 48 0h204.118a47.998 47.998 0 0 1 33.941 14.059l211.882 211.882c18.745 18.745 18.745 49.137 0 67.882zM464 259.882L252.118 48H48v204.118l211.886 211.878L464 259.882zM144 96c-26.51 0-48 21.49-48 48s21.49 48 48 48 48-21.49 48-48-21.49-48-48-48z',
});

export const ChevronLeftIcon = createIcon({
  displayName: 'ChevronLeftIcon',
  viewBox: '0 0 256 512',
  d:
    'M231.293 473.899l19.799-19.799c4.686-4.686 4.686-12.284 0-16.971L70.393 256 251.092 74.87c4.686-4.686 4.686-12.284 0-16.971L231.293 38.1c-4.686-4.686-12.284-4.686-16.971 0L4.908 247.515c-4.686 4.686-4.686 12.284 0 16.971L214.322 473.9c4.687 4.686 12.285 4.686 16.971-.001z',
});

export const ChevronRightIcon = createIcon({
  displayName: 'ChevronRightIcon',
  viewBox: '0 0 256 512',
  d:
    'M24.707 38.101L4.908 57.899c-4.686 4.686-4.686 12.284 0 16.971L185.607 256 4.908 437.13c-4.686 4.686-4.686 12.284 0 16.971L24.707 473.9c4.686 4.686 12.284 4.686 16.971 0l209.414-209.414c4.686-4.686 4.686-12.284 0-16.971L41.678 38.101c-4.687-4.687-12.285-4.687-16.971 0z',
});

export const CheckCircleIcon = createIcon({
  displayName: 'CheckCircleIcon',
  viewBox: '0 0 512 512',
  d:
    'M256 8C119.033 8 8 119.033 8 256s111.033 248 248 248 248-111.033 248-248S392.967 8 256 8zm0 48c110.532 0 200 89.451 200 200 0 110.532-89.451 200-200 200-110.532 0-200-89.451-200-200 0-110.532 89.451-200 200-200m140.204 130.267l-22.536-22.718c-4.667-4.705-12.265-4.736-16.97-.068L215.346 303.697l-59.792-60.277c-4.667-4.705-12.265-4.736-16.97-.069l-22.719 22.536c-4.705 4.667-4.736 12.265-.068 16.971l90.781 91.516c4.667 4.705 12.265 4.736 16.97.068l172.589-171.204c4.704-4.668 4.734-12.266.067-16.971z',
});
