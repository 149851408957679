import React, { useEffect, useState } from 'react';
import {
  Box,
  Grid,
  VStack,
  Heading,
  HStack,
  Tag,
  Button,
  Spacer,
  useToast,
} from '@chakra-ui/react';
import { useParams } from 'react-router-dom';
import kebabCase from 'lodash.kebabcase';
import { getStravaIdFromSub } from '../../auth/utils';
import { useAuth0 } from '@auth0/auth0-react';
import { CyclingRoute } from '../../state/route/route';
import { Resource } from '../../state/api';
import { AthleteMeta } from '../../state/athlete-meta/model';
import { Item } from '../../components/TagsAutoComplete';
import athleteMetaService from '../../state/athlete-meta/service';
import routesService from '../../state/route/service';
import { RouteMap } from '../../components/RouteMap';
import { ElevationChart } from '../../components/ElevationChart';
import { RouteBasicStatsPanel } from '../../components/RouteBasicStatsPanel';
import { RouteDatesPanel } from '../../components/RouteDatesPanel';
import { TagsAutoCompleteWithResources } from '../../components/TagsAutoComplete/with-resrouce';
import { Helmet } from 'react-helmet-async';

interface EditableRouteProps {
  hasRouteBeenModified: boolean;
  selectedTags: Item[];
}

const defaultEditableRouteProps: EditableRouteProps = {
  hasRouteBeenModified: false,
  selectedTags: [],
};

interface PathParams {
  routeId: string;
}

export const RoutePage: React.FC = function () {
  const { getAccessTokenSilently, user } = useAuth0();
  const athleteId = getStravaIdFromSub(user?.sub);
  const { routeId } = useParams<PathParams>();
  const toast = useToast();
  const [editableProps, setEditableProps] = useState<EditableRouteProps>(defaultEditableRouteProps);
  const [athleteMetaResource, setAthleteMetaResource] = useState<Resource<AthleteMeta>>();
  const [routeResource, setRouteResource] = useState<Resource<CyclingRoute>>();

  useEffect(() => {
    const metaResource = athleteMetaService.fetch({
      accessTokenFn: getAccessTokenSilently,
      athleteId: athleteId,
    });

    setAthleteMetaResource(metaResource);

    const routeResource = routesService.fetchById({
      accessTokenFn: getAccessTokenSilently,
      athleteId: athleteId,
      routeId: routeId,
    });

    setRouteResource(routeResource);
  }, []);

  let athleteMeta: AthleteMeta | undefined;
  let route: CyclingRoute | undefined;

  if (routeResource) {
    route = routeResource.read();
  }

  if (athleteMetaResource) {
    athleteMeta = athleteMetaResource.read();
  }

  if (!(route && athleteMeta)) {
    return null;
  }

  const selectedTags = editableProps.selectedTags.map((tag) => tag.value);
  const allTags = [...route.tags, ...selectedTags];
  const uniqueTags = Array.from(new Set(allTags));

  const selectedTagsItems = uniqueTags.map((tag) => {
    return {
      value: tag,
      label: tag,
    };
  });

  const handleSelectedItemsChange = function (selectedItems?: Item[]) {
    if (!selectedItems) {
      return;
    }

    setEditableProps({
      hasRouteBeenModified: true,
      selectedTags: selectedItems,
    });
  };

  const handleNewTagCreated = function (item: Item) {
    const sanitisedItem: Item = {
      value: kebabCase(item.value),
      label: kebabCase(item.label),
    };

    const newSelectedTags: Item[] = [...editableProps.selectedTags, sanitisedItem];

    setEditableProps({
      hasRouteBeenModified: true,
      selectedTags: newSelectedTags,
    });
  };

  const handleOnRouteSave = async function () {
    const token = await getAccessTokenSilently();
    const athlete = getStravaIdFromSub(user?.sub);

    if (!route) {
      return;
    }

    const tagNames = editableProps.selectedTags.map((tag) => tag.value);

    await routesService.patch({
      accessToken: token,
      athleteId: athlete,
      routeId: route._id,
      patch: { tags: tagNames },
    });

    setEditableProps({
      hasRouteBeenModified: false,
      selectedTags: editableProps.selectedTags,
    });

    toast({
      title: 'Success',
      description: 'The changes have been saved.',
      status: 'success',
      duration: 3000,
      isClosable: true,
    });
  };

  const handleOnDiscardChanges = function () {
    setEditableProps(defaultEditableRouteProps);
  };

  return (
    <>
      <Helmet>
        <title>{route.strava.name}</title>
      </Helmet>

      <VStack w="100%">
        <Box w="100%">
          <Heading mb={4} isTruncated>
            {route.strava.name}
            <Tag size="lg" bgColor="primary.100" color="primary.500" mt={2} ml={2}>
              {route.type}
            </Tag>
          </Heading>
        </Box>

        <Grid w="100%" templateColumns="2fr 1fr" gap={4}>
          <VStack w="100%" alignItems="start">
            <RouteMap route={route} height={400} />
            {route.streams ? (
              <ElevationChart
                stream={route.streams}
                width={830}
                height={120}
                nTicksBottom={12}
                nTicksLeft={4}
              />
            ) : null}
          </VStack>

          <Box w="100%">
            <RouteBasicStatsPanel
              distance={route.distance}
              elevationGain={route.elevationGain}
              starred={route.strava.starred}
            />

            {route.strava.description ? (
              <Box color="gray.500" pt={4}>
                {route.strava.description}
              </Box>
            ) : null}

            <RouteDatesPanel
              syncedAt={route.sync.syncedAt}
              createdAt={route.strava.createdAt}
              updatedAt={route.updatedAt}
            />

            {athleteMetaResource ? (
              <Box mt={8}>
                <TagsAutoCompleteWithResources
                  athleteMetaResource={athleteMetaResource}
                  onSelectedItemsChange={handleSelectedItemsChange}
                  onCreateItem={handleNewTagCreated}
                  selectedItems={selectedTagsItems}
                />
              </Box>
            ) : null}

            {editableProps.hasRouteBeenModified ? (
              <HStack alignItems="end" width="100%">
                <Spacer />
                <Button
                  variant="outline"
                  onClick={handleOnDiscardChanges}
                  colorScheme="primary"
                  size="sm"
                >
                  Discard changes
                </Button>
                <Button colorScheme="primary" onClick={handleOnRouteSave} size="sm">
                  Save
                </Button>
              </HStack>
            ) : null}
          </Box>
        </Grid>
      </VStack>
    </>
  );
};
