import React from 'react';
import { Grid } from '@chakra-ui/react';
import { RouteCardFallback } from '../RouteCard/fallback';

export interface Props {
  numCards?: number;
  numColumns?: number;
}

export const RoutesGridFallback: React.FC<Props> = function ({ numCards = 9, numColumns = 3 }) {
  return (
    <Grid templateColumns={`repeat(${numColumns}, 1fr)`} gridGap={5}>
      {[...Array(numCards)].map((e, i) => {
        return <RouteCardFallback key={`card-fallback-${i}`} />;
      })}
    </Grid>
  );
};
