import React, { useEffect, useState, Suspense } from 'react';
import { VStack, Grid, Box } from '@chakra-ui/react';
import { useRecoilState } from 'recoil';
import { CyclingRouteLight } from '../../state/route/route';
import routesService from '../../state/route/service';
import { useAuth0 } from '@auth0/auth0-react';
import { getStravaIdFromSub } from '../../auth/utils';
import { RoutesFilterState, routesFilterState } from '../../state/route/route-filters';
import { RoutesFilters } from '../../components/RoutesFilters';
import { RoutesGridWithResource } from '../../components/RoutesGrid/with-resource';
import { CollectionRes, Resource } from '../../state/api';
import { RoutesGridFallback } from '../../components/RoutesGrid/fallback';
import { Helmet } from 'react-helmet-async';

export const GridTab: React.FC = () => {
  const { getAccessTokenSilently, user } = useAuth0();
  const athleteId = getStravaIdFromSub(user?.sub);
  const [filters, setFilters] = useRecoilState<RoutesFilterState>(routesFilterState);
  const [routesResource, setRoutesResource] = useState<
    Resource<CollectionRes<CyclingRouteLight>>
  >();

  useEffect(() => {
    const resource = routesService.fetchMany({
      accessTokenFn: getAccessTokenSilently,
      athleteId: athleteId,
      types: filters.types,
      minDistance: filters.minDistance,
      maxDistance: filters.maxDistance,
      minElevationGain: filters.minElevationGain,
      maxElevationGain: filters.maxElevationGain,
    });

    setRoutesResource(resource);
  }, []);

  const handleApplyFilters = function (filtersState: RoutesFilterState) {
    setFilters(filtersState);

    const resource = routesService.fetchMany({
      accessTokenFn: getAccessTokenSilently,
      athleteId: athleteId,
      types: filtersState.types,
      minDistance: filtersState.minDistance,
      maxDistance: filtersState.maxDistance,
      minElevationGain: filtersState.minElevationGain,
      maxElevationGain: filtersState.maxElevationGain,
    });

    setRoutesResource(resource);
  };

  const handleOnPageChange = function (cursor: string) {
    const resource = routesService.fetchMany({
      accessTokenFn: getAccessTokenSilently,
      athleteId: athleteId,
      types: filters.types,
      minDistance: filters.minDistance,
      maxDistance: filters.maxDistance,
      minElevationGain: filters.minElevationGain,
      maxElevationGain: filters.maxElevationGain,
      cursor: cursor,
    });

    setRoutesResource(resource);
  };

  return (
    <>
      <Helmet>
        <title>Explore | Grid</title>
      </Helmet>

      <Grid templateColumns="1fr 4fr" gap={4}>
        <VStack>
          <RoutesFilters onApply={handleApplyFilters} />
        </VStack>

        {routesResource ? (
          <Suspense fallback={<RoutesGridFallback />}>
            <RoutesGridWithResource resource={routesResource} onGoToPage={handleOnPageChange} />
          </Suspense>
        ) : null}
      </Grid>
    </>
  );
};

export default GridTab;
