export interface PageCursor {
  cursor: string;
  cursorNext: string;
}

export interface CollectionRes<T> {
  items: T[];
  pagination: PageCursor;
}

export interface CollectionResSinglePage<T> {
  items: T[];
}

export interface Resource<T> {
  read: () => T;
}
export const wrapPromise = function (promise: Promise<any>): Resource<any> {
  let status = 'pending';
  let result: any;
  let suspender = promise.then(
    (r) => {
      status = 'success';
      result = r;
    },
    (e) => {
      status = 'error';
      result = e;
    },
  );
  return {
    read() {
      if (status === 'pending') {
        throw suspender;
      } else if (status === 'error') {
        throw result;
      } else if (status === 'success') {
        return result;
      }
    },
  };
};
