import React from 'react';
import { NotAuthorisedError } from './not-authorised';
import { NotFoundError } from './not-found';
import { ServerSideError } from './server-side';
import { AxiosError } from 'axios';

export interface Props {
  error: Error;
}

export const ErrorSwitcher: React.FC<Props> = function ({ error }) {
  let code = '';

  if (error.hasOwnProperty('isAxiosError')) {
    const err = error as AxiosError;
    code = err.code ? err.code : '';
  }

  if (code === '403') {
    return <NotAuthorisedError />;
  }

  if (code === '404') {
    return <NotFoundError />;
  }

  return <ServerSideError />;
};
