import React, { FunctionComponent } from 'react';
import {
  Slider,
  SliderTrack,
  SliderFilledTrack,
  SliderThumb,
  FormLabel,
  FormControl,
  HStack,
  Spacer,
} from '@chakra-ui/react';
import { DistanceFormatterProps } from '../DistanceFormatter';
import { AltitudeFormatterProps } from '../AltitudeFormatter';

export interface SliderFilterProps {
  label: string;
  min: number;
  max: number;
  value: number;
  unitFormatter: FunctionComponent<DistanceFormatterProps | AltitudeFormatterProps>;
  onChange: (value: number) => void;
  defaultValue: number;
}

export const SliderFilter: React.FC<SliderFilterProps> = ({
  label,
  min = 0,
  max = 500000,
  value,
  unitFormatter,
  onChange,
  defaultValue,
}) => {
  return (
    <FormControl>
      <HStack marginTop={2}>
        <FormLabel fontSize="xs" mb={0}>
          {label}
        </FormLabel>
        <Spacer />
        {React.createElement(unitFormatter, { value: value })}
      </HStack>
      <Slider
        onChange={onChange}
        colorScheme="primary"
        min={min}
        max={max}
        defaultValue={defaultValue}
      >
        <SliderTrack>
          <SliderFilledTrack />
        </SliderTrack>
        <SliderThumb />
      </Slider>
    </FormControl>
  );
};
