import React from 'react';
import { Box, Skeleton, VStack, HStack, Spacer } from '@chakra-ui/react';

export interface Props {
  width?: number;
}

export const RouteCardFallback: React.FC<Props> = function ({ width = 300 }) {
  return (
    <Box maxW="sm" borderWidth="1px" borderRadius="lg" overflow="hidden" w={width} pos="relative">
      <Skeleton height={175} startColor="gray.100" endColor="gray.200" />
      <VStack p={4}>
        <HStack w="100%" pb={4}>
          <Skeleton height={4} width={48} startColor="gray.100" endColor="gray.200" />
          <Spacer />
          <Skeleton
            height={4}
            width={12}
            startColor="gray.100"
            endColor="gray.200"
            borderRadius="lg"
          />
        </HStack>
        <Skeleton height={6} width="100%" startColor="gray.100" endColor="gray.200" />
      </VStack>
    </Box>
  );
};
