import React from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Button,
  useDisclosure,
} from '@chakra-ui/react';
import { StravaSyncState } from '../../state/routes-sync/routes-sync';
import { SyncModalReady } from './ready';
import { SyncModalWorking } from './working';
import { SyncModalSuccess } from './success';
import { SyncModalError } from './error';
import { SyncIcon } from '../Icons';

export interface SyncModalProps {
  syncState: StravaSyncState;
  onSyncRequest: () => void;
  onEditLastSyncedRoutes: () => void;
  onExploreRoutes: () => void;
  onModalClose: () => void;
}

export const SyncModal: React.FC<SyncModalProps> = ({
  syncState,
  onSyncRequest,
  onEditLastSyncedRoutes,
  onExploreRoutes,
  onModalClose,
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { state } = syncState;

  const handleOnModalClose = function () {
    onClose();
    onModalClose();
  };

  const handleOnExploreRoutes = function () {
    onExploreRoutes();
    handleOnModalClose();
  };

  return (
    <>
      <Button onClick={onOpen} size="sm">
        <SyncIcon mr={2} />
        Sync
      </Button>

      <Modal isOpen={isOpen} onClose={handleOnModalClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Synchronise Strava routes</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {state === 'waiting' ? <SyncModalReady onSyncRequest={onSyncRequest} /> : null}
            {state === 'running' ? <SyncModalWorking /> : null}
            {state === 'success' ? (
              <SyncModalSuccess
                skipped={syncState.lastSync.skipped}
                imported={syncState.lastSync.imported.length}
                updated={syncState.lastSync.updated.length}
                onEditLastSyncedRoutes={onEditLastSyncedRoutes}
                onExploreRoutes={handleOnExploreRoutes}
              />
            ) : null}
            {state === 'error' ? <SyncModalError /> : null}
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};
