import React from 'react';
import { GenericError } from '../GenericError';
import { NotFoundIcon } from '../Icons';

export const NotFoundError: React.FC = function () {
  return (
    <GenericError
      title="Oops!"
      subtitle="We can't seem to find what you're looking for."
      code="404"
      icon={NotFoundIcon}
    />
  );
};
