import React from 'react';
import { Box, VStack } from '@chakra-ui/react';
import { TagItem } from './tag.item';

export interface Props {
  tags: { [key: string]: number };
  onTagClick: (name: string) => void;
}

export const TagList: React.FC<Props> = function ({ tags, onTagClick }) {
  return (
    <VStack spacing={2} w="100%" alignItems="start">
      {Object.keys(tags).map((tagName) => (
        <TagItem
          name={tagName}
          nRoutes={tags[tagName]}
          key={`tag-${tagName}`}
          onClick={onTagClick}
        />
      ))}
    </VStack>
  );
};
