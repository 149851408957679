import polyline from '@mapbox/polyline';

export type lat = number;
export type lon = number;

export interface BoundingBox {
  bottomLeft: {
    lon: lon;
    lat: lat;
  };
  topRight: {
    lon: lon;
    lat: lat;
  };
}

const swap = function (array: [lat, lon]): [lon, lat] {
  return [array[1], array[0]];
};

// https://github.com/mapbox/polyline/issues/74
export const decode = function (polylineString: string): Array<[lon, lat]> {
  const originalDecoded = polyline.decode(polylineString);
  return originalDecoded.map((entry) => swap(entry));
};

export const toJSON = polyline.toGeoJSON;
