import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';

import { env } from '../../env';
import {
  CyclingRoute,
  CyclingRouteModifiableProps,
  CyclingRouteLight,
  CyclingRouteType,
} from './route';
import { CollectionRes, CollectionResSinglePage, Resource, wrapPromise } from '../api';
import qs from 'qs';
import { BoundingBox } from '../../polyline';

const patch = async (params: {
  accessToken: string;
  athleteId: string;
  routeId: string;
  patch: CyclingRouteModifiableProps;
}) => {
  const reqConfig: AxiosRequestConfig = {
    method: 'PATCH',
    baseURL: env.api,
    url: `/${params.athleteId}/routes/${params.routeId}`,
    headers: {
      Authorization: `Bearer ${params.accessToken}`,
    },
    data: { ...params.patch },
  };

  await axios.request(reqConfig);
};

const fetchGeo = function (p: {
  accessTokenFn: () => Promise<string>;
  athleteId: string;
  types: CyclingRouteType[];
  boundingBox: BoundingBox;
}): Resource<CollectionResSinglePage<CyclingRouteLight>> {
  const request = p
    .accessTokenFn()
    .then((token) => {
      const reqConfig: AxiosRequestConfig = {
        method: 'GET',
        baseURL: env.api,
        url: `/${p.athleteId}/routes/geo`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          blLon: p.boundingBox.bottomLeft.lon,
          blLat: p.boundingBox.bottomLeft.lat,
          trLon: p.boundingBox.topRight.lon,
          trLat: p.boundingBox.topRight.lat,
          types: p.types,
        },
        paramsSerializer: (p) => {
          return qs.stringify(p, { arrayFormat: 'comma' });
        },
      };

      return axios.request(reqConfig);
    })
    .then((res) => {
      return {
        items: res.data.items,
      };
    });

  return wrapPromise(request);
};

const fetchById = (p: {
  accessTokenFn: () => Promise<string>;
  athleteId: string;
  routeId: string;
}): Resource<CyclingRoute> => {
  const request = p
    .accessTokenFn()
    .then((token) => {
      const reqConfig: AxiosRequestConfig = {
        method: 'GET',
        baseURL: env.api,
        url: `/${p.athleteId}/routes/${p.routeId}`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };

      return axios.request<AxiosResponse<CyclingRoute>>(reqConfig);
    })
    .then((res) => res.data);

  return wrapPromise(request);
};

const fetchMany = function (p: {
  accessTokenFn: () => Promise<string>;
  athleteId: string;
  cursor?: string | undefined;
  minDistance?: number;
  maxDistance?: number;
  minElevationGain?: number;
  maxElevationGain?: number;
  tags?: string[];
  types: CyclingRouteType[];
  syncedAt?: number;
}): Resource<CollectionRes<CyclingRouteLight>> {
  const routesPromise = p
    .accessTokenFn()
    .then((token) => {
      const reqConfig: AxiosRequestConfig = {
        method: 'GET',
        baseURL: env.api,
        url: `/${p.athleteId}/routes`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          cursor: p.cursor,
          minD: p.minDistance,
          maxD: p.maxDistance,
          minE: p.minElevationGain,
          maxE: p.maxElevationGain,
          types: p.types,
          tags: p.tags,
          syncedAt: p.syncedAt,
        },
        paramsSerializer: (p) => {
          return qs.stringify(p, { arrayFormat: 'comma' });
        },
      };

      return axios.request(reqConfig);
    })
    .then((res) => {
      return {
        items: res.data.items,
        pagination: res.data.pagination,
      };
    });

  return wrapPromise(routesPromise);
};

const routesService = {
  patch: patch,
  fetchGeo: fetchGeo,
  fetchById: fetchById,
  fetchMany: fetchMany,
};

export default routesService;
