import React from 'react';
import {
  Box,
  Heading,
  VStack,
  Table,
  Tr,
  Tbody,
  Td,
  HStack,
  Button,
  Tooltip,
  Text,
} from '@chakra-ui/react';
import { CheckCircleIcon } from '../Icons';

export interface SyncModalSuccessProps {
  skipped: number;
  imported: number;
  updated: number;
  onEditLastSyncedRoutes: () => void;
  onExploreRoutes: () => void;
}

export const SyncModalSuccess: React.FC<SyncModalSuccessProps> = ({
  skipped,
  imported,
  updated,
  onExploreRoutes,
}) => {
  return (
    <Box maxW="md">
      <VStack>
        <CheckCircleIcon boxSize={20} color="primary.500" />
        <Heading as="h2" size="md" mb={5}>
          Synchronisation finished!
        </Heading>
        <Table variant="simple" mb={5}>
          <Tbody>
            <Tr>
              <Td>
                <Tooltip
                  hasArrow
                  label="Routes that have been previously imported and haven't changed on Strava since then"
                >
                  <Text display="inline-block">Skipped</Text>
                </Tooltip>
              </Td>
              <Td isNumeric>{skipped}</Td>
            </Tr>
            <Tr>
              <Td>Imported</Td>
              <Td isNumeric>{imported}</Td>
            </Tr>
            <Tr>
              <Td>Updated</Td>
              <Td isNumeric>{updated}</Td>
            </Tr>
          </Tbody>
        </Table>

        <HStack mb={5}>
          <Button size="sm" onClick={onExploreRoutes} colorScheme="primary">
            Explore routes
          </Button>
        </HStack>
      </VStack>
    </Box>
  );
};
