import React from 'react';
import { Button } from '@chakra-ui/react';
import { ExploreIcon } from '../Icons';

export interface Props {
  onClick: () => void;
  text?: string;
}

export const ExploreButton: React.FC<Props> = ({ onClick, text }) => (
  <Button size="sm" colorScheme="primary" onClick={onClick}>
    <ExploreIcon mr={2} />
    {text ? text : 'Explore'}
  </Button>
);
