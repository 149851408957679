import React from 'react';
import { Box, HStack, IconButton, Button } from '@chakra-ui/react';
import { ChevronLeftIcon, ChevronRightIcon } from '../Icons';

export interface Props {
  isCurrentPageFirst: boolean;
  isCurrentPageLast: boolean;
  currentPage: number;
  onJumpToPage: (page: number) => void;
  onGoPrevious: () => void;
  onGoNext: () => void;
  pagesAvailable: number[];
  hasMoreItems: boolean;
}

export const GridPaginator: React.FC<Props> = ({
  isCurrentPageLast,
  isCurrentPageFirst,
  onGoPrevious,
  onGoNext,
  currentPage,
  onJumpToPage,
  pagesAvailable,
  hasMoreItems,
}) => {
  return (
    <HStack pt={6}>
      <IconButton
        icon={<ChevronLeftIcon boxSize={8} />}
        size="md"
        aria-label="go to previous page"
        variant="ghost"
        colorScheme="primary"
        disabled={isCurrentPageFirst}
        onClick={onGoPrevious}
      />
      {pagesAvailable.map((page, index) => (
        <Box key={`page-${page}`} fontSize="lg" pl={1} pr={1}>
          {page === currentPage ? (
            <Button
              colorScheme="primary"
              onClick={() => {
                onJumpToPage(page);
              }}
            >
              {page}
            </Button>
          ) : (
            <Button
              onClick={() => {
                onJumpToPage(page);
              }}
            >
              {page}
            </Button>
          )}
        </Box>
      ))}

      {hasMoreItems ? (
        <Button variant="ghost" onClick={onGoNext}>
          ...
        </Button>
      ) : null}

      <IconButton
        icon={<ChevronRightIcon boxSize={8} />}
        size="md"
        aria-label="go to next page"
        variant="ghost"
        colorScheme="primary"
        disabled={isCurrentPageLast && !hasMoreItems}
        onClick={onGoNext}
      />
    </HStack>
  );
};
