import React from 'react';
import { Box } from '@chakra-ui/react';
import { meters } from '../../types';

export interface AltitudeFormatterProps {
  value: number; // should always be meters
}

export const AltitudeFormatter: React.FC<AltitudeFormatterProps> = ({ value }) => {
  return <Box>{Math.round(value).toLocaleString()} m</Box>;

  // return <Box>{Math.round(value * 3.2808)} ft</Box>;
};

export const formatAltitude = function (value: meters) {
  return `${Math.round(value).toLocaleString()} m`;
};
