import React from 'react';
import { Box, Center, Spinner as ChakraSpinner } from '@chakra-ui/react';

export interface Props {
  verticalPadding?: number;
}

export const Spinner: React.FC<Props> = function ({ verticalPadding = 16 }) {
  return (
    <Box width="100%" pt={verticalPadding} pb={verticalPadding}>
      <Center>
        <ChakraSpinner
          thickness="4px"
          speed="0.65s"
          emptyColor="gray.200"
          color="primary.500"
          size="xl"
        />
      </Center>
    </Box>
  );
};
