import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useHistory } from 'react-router-dom';
import Header from '../../components/Header';

export const HeaderWithContext: React.FC = function () {
  const { isAuthenticated, user, loginWithRedirect, logout } = useAuth0();
  const history = useHistory();

  const navToExplore = () => {
    history.push('/explore/grid');
  };

  const handleOnLogin = async function () {
    await loginWithRedirect({ connection: 'Strava' });
  };

  return (
    <Header
      onLogin={async () => {
        await handleOnLogin();
      }}
      onLogout={logout}
      isAuthenticated={isAuthenticated}
      userName={user?.name ? user.name : ''}
      userPicture={user?.picture ? user.picture : ''}
      onExploreMyRoutes={navToExplore}
    />
  );
};
