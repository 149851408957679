import React from 'react';
import { Box, Button, Center, List, ListIcon, ListItem, Text } from '@chakra-ui/react';
import { CheckCircleIcon } from '../Icons';

export interface SyncModalReadyProps {
  onSyncRequest: () => void;
}

export const SyncModalReady: React.FC<SyncModalReadyProps> = ({ onSyncRequest }) => {
  return (
    <Box maxW="md">
      <Box width="100%">
        <Text mb={5}>
          Unfortunately Strava doesn't offer any automatic ways to synchronise routes. So you need
          to trigger the process manually.
        </Text>
        <Text mb={5}>When synchronising your routes:</Text>
        <List spacing={2} ml={5}>
          <ListItem>
            <ListIcon as={CheckCircleIcon} color="primary.500" />
            Retrieve all your routes from Strava.
          </ListItem>
          <ListItem>
            <ListIcon as={CheckCircleIcon} color="primary.500" />
            Import any route that was created since the last time you synchronise your routes.
          </ListItem>
          <ListItem>
            <ListIcon as={CheckCircleIcon} color="primary.500" />
            Update the routes that have been modified on Strava since the last sync
          </ListItem>
        </List>
        <Center h="100%" pt={7}>
          <Button colorScheme="primary" onClick={onSyncRequest} mb={5}>
            Synchronise now
          </Button>
        </Center>
      </Box>
    </Box>
  );
};
