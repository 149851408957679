import React, { useState, Suspense, useEffect } from 'react';
import {
  Box,
  Grid,
  useDisclosure,
  VStack,
  Button,
  Collapse,
  HStack,
  Spacer,
  Text,
} from '@chakra-ui/react';
import { useRecoilState } from 'recoil';
import { useAuth0 } from '@auth0/auth0-react';
import { getStravaIdFromSub } from '../../auth/utils';
import routesService from '../../state/route/service';
import {
  maxRoutesFiltersState,
  RoutesFilterState,
  routesFilterState,
} from '../../state/route/route-filters';
import { CyclingRouteLight } from '../../state/route/route';
import { RoutesFilters } from '../../components/RoutesFilters';
import { FiltersIcon, TagsIcon } from '../../components/Icons';
import { RoutesGridWithResource } from '../../components/RoutesGrid/with-resource';
import { CollectionRes, Resource } from '../../state/api';
import { AthleteMeta } from '../../state/athlete-meta/model';
import { TagList } from './tag.list';
import { RoutesGridFallback } from '../../components/RoutesGrid/fallback';
import { Helmet } from 'react-helmet-async';

interface Props {
  athleteMetaResource: Resource<AthleteMeta>;
}

export const TagsTab: React.FC<Props> = function ({ athleteMetaResource }) {
  const athleteMeta = athleteMetaResource.read();
  const { getAccessTokenSilently, user } = useAuth0();
  const athleteId = getStravaIdFromSub(user?.sub);

  const [routesResource, setRoutesResource] = useState<
    Resource<CollectionRes<CyclingRouteLight>>
  >();

  const [filters, setFilters] = useRecoilState<RoutesFilterState>(routesFilterState);
  const [selectedTags, setSelectedTags] = useState<string[]>([]);
  const { isOpen, onToggle } = useDisclosure();

  const handleTagClick = function (tagName: string) {
    const resource = routesService.fetchMany({
      accessTokenFn: getAccessTokenSilently,
      athleteId: athleteId,
      types: filters.types,
      minDistance: filters.minDistance,
      maxDistance: filters.maxDistance,
      minElevationGain: filters.minElevationGain,
      maxElevationGain: filters.maxElevationGain,
      tags: [tagName],
    });

    setSelectedTags([tagName]);
    setRoutesResource(resource);
  };

  const handleOnPageChange = function (cursor: string) {
    const resource = routesService.fetchMany({
      accessTokenFn: getAccessTokenSilently,
      athleteId: athleteId,
      types: filters.types,
      minDistance: filters.minDistance,
      maxDistance: filters.maxDistance,
      minElevationGain: filters.minElevationGain,
      maxElevationGain: filters.maxElevationGain,
      tags: selectedTags,
      cursor: cursor,
    });

    setRoutesResource(resource);
  };

  const applyFilters = function (filterState: RoutesFilterState) {
    setFilters({ ...filterState });

    const resource = routesService.fetchMany({
      accessTokenFn: getAccessTokenSilently,
      athleteId: athleteId,
      types: filterState.types,
      minDistance: filterState.minDistance,
      maxDistance: filterState.maxDistance,
      minElevationGain: filterState.minElevationGain,
      maxElevationGain: filterState.maxElevationGain,
      tags: selectedTags,
    });

    setRoutesResource(resource);
  };

  return (
    <>
      <Helmet>
        <title>Explore | Tags</title>
      </Helmet>
      <Box w="100%">
        <Grid templateColumns="1fr 3fr">
          <VStack alignItems="stretch" pr={4}>
            <HStack w="100%">
              <Spacer />
              <Button
                onClick={onToggle}
                colorScheme="primary"
                variant={isOpen ? 'ghost' : 'solid'}
                size="sm"
                leftIcon={<FiltersIcon />}
                mb={2}
              >
                Filters
              </Button>
            </HStack>
            <Collapse in={isOpen} animateOpacity>
              <Box mb={4}>
                <RoutesFilters onApply={applyFilters} initialState={maxRoutesFiltersState} />
              </Box>
            </Collapse>

            <TagList tags={athleteMeta.tagsCount} onTagClick={handleTagClick} />
          </VStack>

          <VStack>
            {selectedTags.length === 0 ? (
              <VStack mt={20}>
                <TagsIcon color="gray.300" boxSize={24} />
                <Text color="gray.500">Please select one or more tags to start</Text>
              </VStack>
            ) : null}

            {routesResource ? (
              <Suspense fallback={<RoutesGridFallback numCards={6} />}>
                <RoutesGridWithResource resource={routesResource} onGoToPage={handleOnPageChange} />
              </Suspense>
            ) : null}
          </VStack>
        </Grid>
      </Box>
    </>
  );
};
