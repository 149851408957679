import { atom } from 'recoil';
import { CyclingRoute } from '../route/route';

export interface StravaSyncState {
  startedAt: string;
  finishedAt: string;
  lastSync: {
    skipped: number;
    updated: CyclingRoute[];
    imported: CyclingRoute[];
  };
  state: 'success' | 'error' | 'running' | 'waiting';
}

export const defaultStravaSyncState: StravaSyncState = {
  startedAt: '',
  finishedAt: '',
  lastSync: {
    skipped: 0,
    updated: [],
    imported: [],
  },
  state: 'waiting',
};

export const stravaSyncState = atom({
  key: 'routesSync',
  default: defaultStravaSyncState,
});

export interface StravaSyncAPIResp {
  skipped: number;
  updated: CyclingRoute[];
  imported: CyclingRoute[];
}
