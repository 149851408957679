import { extendTheme } from '@chakra-ui/react';

const overrides = {
  colors: {
    primary: {
      100: '#B3EBE0',
      200: '#80DDCC',
      300: '#4DCFB8',
      400: '#26C5A8',
      500: '#00BB99',
      600: '#00B591',
      700: '#00AC86',
      800: '#00A47C',
      900: '#00966B',
    },
    map: {
      default: 'rgba(12, 162, 95, 0.4)',
      defaultHexWithoutHash: '0CA25F',
    },
    routeTypes: {
      road: '#3182CE',
      gravel: '#DD6B20',
      mountain: '#805AD5',
    },
  },
  styles: {
    global: {
      'html, body': {
        fontSize: 'sm',
      },
    },
  },
};

export const layout = {
  maxContentWidth: '1248px',
};

const customTheme = extendTheme(overrides);

export default customTheme;
