export interface AthleteMeta {
  stravaId: string;
  lastSyncAt: number;
  tagsCount: { [key: string]: number };
}

export const zeroAthleteMeta: AthleteMeta = {
  stravaId: '',
  lastSyncAt: 0,
  tagsCount: {},
};
