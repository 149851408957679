import React, { useState } from 'react';
import ReactMapGL, {
  Source,
  Layer,
  LayerProps,
  ViewportProps,
  WebMercatorViewport,
} from 'react-map-gl';
import { CyclingRouteLight } from '../../state/route/route';
import { Pin } from './pin';
import { Feature, LineString } from 'geojson';
import theme from '../../theme';
import { BoundingBox, decode } from '../../polyline';

export interface Props {
  routes: CyclingRouteLight[];
  height?: number;
  width?: number;
  defaultLongitude?: number;
  defaultLatitude?: number;
  defaultZoom?: number;
  onInteractionStateChange: (bb: BoundingBox) => void;
  onRouteSelected: (route: CyclingRouteLight) => void;
  onMapClick: () => void;
}

export const RoutesMap: React.FC<Props> = ({
  routes,
  height = 500,
  width = 600,
  defaultLatitude = 47.37741209861122,
  defaultLongitude = 8.54049876679653,
  defaultZoom = 10,
  onInteractionStateChange,
  onRouteSelected,
  onMapClick,
}) => {
  const [viewport, setViewport] = useState<ViewportProps>({
    width: width,
    height: height,
    latitude: defaultLatitude,
    longitude: defaultLongitude,
    zoom: defaultZoom,
  });

  const [selectedRoute, setSelectedRoute] = useState<string>('');

  const handleRouteSelection = (route: CyclingRouteLight) => {
    setSelectedRoute(route.summaryPolyline);
    onRouteSelected(route);
  };

  const handleMapClick = () => {
    setSelectedRoute('');
    onMapClick();
  };

  const handleViewportChange = (nextViewPort: ViewportProps) => {
    setViewport(nextViewPort);
  };

  const handleInteractionStateChange = (s: any) => {
    if (
      s.isDragging ||
      s.inTransition ||
      s.isRotating ||
      s.isZooming ||
      s.isHovering ||
      s.isPanning
    )
      return;

    const webMercator = new WebMercatorViewport({
      // @ts-ignore
      width: viewport.width,
      // @ts-ignore
      height: viewport.height,
      latitude: viewport.latitude,
      longitude: viewport.longitude,
      zoom: viewport.zoom,
      pitch: viewport.pitch,
      bearing: viewport.bearing,
      altitude: viewport.altitude,
    });

    const bbCoordinates = webMercator.getBounds();

    onInteractionStateChange({
      bottomLeft: {
        lon: bbCoordinates[0][1],
        lat: bbCoordinates[0][0],
      },
      topRight: {
        lon: bbCoordinates[1][1],
        lat: bbCoordinates[1][0],
      },
    });
  };

  const routePath: Feature<LineString> | undefined = selectedRoute
    ? {
        type: 'Feature',
        properties: {},
        geometry: {
          type: 'LineString',
          coordinates: decode(selectedRoute),
        },
      }
    : undefined;

  const routeLayer: LayerProps = {
    id: 'route-layer',
    type: 'line',
    layout: {
      'line-join': 'round',
      'line-cap': 'round',
    },
    paint: {
      'line-color': theme.colors.map.default,
      'line-width': 5,
    },
  };

  return (
    <ReactMapGL
      {...viewport}
      width="100%"
      onViewportChange={handleViewportChange}
      onClick={handleMapClick}
      onInteractionStateChange={handleInteractionStateChange}
    >
      {routes.map((route) => (
        <Pin
          route={route}
          onClick={handleRouteSelection}
          currentlySelectedRoute={selectedRoute}
          key={`route-${route._id}`}
        />
      ))}
      {routePath ? (
        <Source id="my-data" type="geojson" data={routePath}>
          <Layer {...routeLayer} />
        </Source>
      ) : null}
    </ReactMapGL>
  );
};
