import React from 'react';
import { Resource } from '../../state/api';
import { CyclingRoute } from '../../state/route/route';
import { RouteCardDetailed } from './index';

export interface Props {
  resource: Resource<CyclingRoute>;
  onGoToRoute: (stravaRouteId: string) => void;
  onAddToCompareList: (route: CyclingRoute) => void;
}

export const RouteCardDetailedWithResource: React.FC<Props> = function ({
  resource,
  onGoToRoute,
  onAddToCompareList,
}) {
  const route = resource.read();

  return (
    <RouteCardDetailed
      route={route}
      onAddToCompareList={onAddToCompareList}
      onGoToRoute={onGoToRoute}
    />
  );
};
