import React from 'react';
import { CollectionResSinglePage, Resource } from '../../state/api';
import { CyclingRouteLight } from '../../state/route/route';
import { RoutesMap } from './index';
import { BoundingBox } from '../../polyline';

interface Props {
  resource: Resource<CollectionResSinglePage<CyclingRouteLight>>;
  height?: number;
  width?: number;
  defaultLongitude?: number;
  defaultLatitude?: number;
  defaultZoom?: number;
  onInteractionStateChange: (bb: BoundingBox) => void;
  onRouteSelected: (route: CyclingRouteLight) => void;
  onMapClick: () => void;
}

export const RoutesMapWithResource: React.FC<Props> = function ({
  resource,
  onInteractionStateChange,
  onMapClick,
  onRouteSelected,
  height,
  width,
}) {
  const singlePageColl = resource.read();

  return (
    <RoutesMap
      routes={singlePageColl.items}
      onInteractionStateChange={onInteractionStateChange}
      onRouteSelected={onRouteSelected}
      onMapClick={onMapClick}
      height={height}
      width={width}
    />
  );
};
