import React from 'react';
import { Marker } from 'react-map-gl';
import { MapMarker } from '../Icons';
import { Tooltip } from '@chakra-ui/react';
import { CyclingRouteLight } from '../../state/route/route';

export interface Props {
  route: CyclingRouteLight;
  currentlySelectedRoute: string; // stravaId of the currently selected route
  onClick: (route: CyclingRouteLight) => void;
}

export const Pin: React.FC<Props> = ({ route, currentlySelectedRoute, onClick }) => {
  return (
    <Marker
      longitude={route.startPoint.coordinates[1]}
      latitude={route.startPoint.coordinates[0]}
      offsetLeft={-12}
      offsetTop={-24}
    >
      <Tooltip label={`${route.strava.name}`}>
        <MapMarker
          color={route._id === currentlySelectedRoute ? 'primary.700' : 'primary.500'}
          boxSize={'24px'}
          onClick={() => onClick(route)}
        />
      </Tooltip>
    </Marker>
  );
};
