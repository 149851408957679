import React from 'react';
import { Box, Heading, Text, VStack } from '@chakra-ui/react';
import { BrokenIcon } from '../Icons';

export const SyncModalError: React.FC = () => {
  return (
    <Box maxW="md" mb={5}>
      <VStack>
        <BrokenIcon boxSize={20} color="yellow.500" />
        <Heading as="h2" size="md">
          Ops! Something went wrong :(
        </Heading>
        <Text color="gray.400" textAlign="center">
          The synchronisation failed, please try again later.
        </Text>
      </VStack>
    </Box>
  );
};
