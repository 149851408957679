import React, { MouseEventHandler } from 'react';
import { Avatar, Menu, MenuButton, MenuList, MenuItem } from '@chakra-ui/react';

export interface Props {
  name: string;
  picture: string;
  onLogout: MouseEventHandler;
}

export const ProfileMenu: React.FC<Props> = ({ name, picture, onLogout }) => {
  return (
    <Menu>
      <MenuButton>
        <Avatar name={name} src={picture} />
      </MenuButton>
      <MenuList>
        <MenuItem onClick={onLogout}>Log-out</MenuItem>
      </MenuList>
    </Menu>
  );
};
