import React from 'react';
import {
  Box,
  Skeleton,
  SkeletonText,
  Tag,
  HStack,
  VStack,
  Heading,
  Stat,
  StatLabel,
  StatNumber,
  Button,
  Grid,
} from '@chakra-ui/react';
import { DistanceFormatter } from '../DistanceFormatter';
import { AltitudeFormatter } from '../AltitudeFormatter';
import { CyclingRoute } from '../../state/route/route';
import { ElevationChart } from '../ElevationChart';

export interface Props {
  route: CyclingRoute;
  onGoToRoute: (stravaRouteId: string) => void;
  onAddToCompareList: (route: CyclingRoute) => void;
}

export const RouteCardDetailed: React.FC<Props> = ({ route, onAddToCompareList, onGoToRoute }) => {
  return (
    <Box borderWidth="1px" borderRadius="md" w="100%" p={2}>
      <VStack alignItems="start">
        <Heading size="md" isTruncated pb={1} pl={2} pt={1}>
          {route.strava.name}
        </Heading>

        <Box>
          {route.tags.map((tag) => (
            <Tag key={`tag-${tag}`} mr={1} color="gray.600" size="sm">
              #{tag}
            </Tag>
          ))}
        </Box>

        <VStack w="100%">
          <HStack w="100%">
            <VStack w="100%">
              <HStack w="100%" pl={3} pr={10}>
                <Stat size="md">
                  <StatLabel>Type</StatLabel>
                  <StatNumber>{route.type}</StatNumber>
                </Stat>
                <Stat size="md">
                  <StatLabel>Distance</StatLabel>
                  <StatNumber>
                    <DistanceFormatter value={route.distance} />
                  </StatNumber>
                </Stat>
                <Stat size="md">
                  <StatLabel>Elevation Gain</StatLabel>
                  <StatNumber>
                    <AltitudeFormatter value={route.elevationGain} />
                  </StatNumber>
                </Stat>
              </HStack>

              <HStack pt={4}>
                <Button size="xs" colorScheme="primary" onClick={() => onAddToCompareList(route)}>
                  Add to list to compare
                </Button>
                <Button size="xs" onClick={() => onGoToRoute(route._id)}>
                  Go to route
                </Button>
              </HStack>
            </VStack>

            <Box>
              {route.streams ? (
                <ElevationChart stream={route.streams} width={800} height={130} nTicksLeft={4} />
              ) : null}
            </Box>
          </HStack>
        </VStack>
      </VStack>
    </Box>
  );
};

export const RouteCardDetailedFallback: React.FC = () => {
  return (
    <Box borderWidth="1px" borderRadius="md" w="100%" p={2}>
      <Box pb={2}>
        <Skeleton height={10} startColor="gray.100" endColor="gray.300" />
      </Box>

      <Grid templateColumns="1fr 2fr" gap={4}>
        <Box w="100%">
          <SkeletonText
            mt="4"
            noOfLines={4}
            spacing="4"
            startColor="gray.100"
            endColor="gray.300"
          />
        </Box>
        <Box w="100%">
          <Skeleton height={100} startColor="gray.100" endColor="gray.300" />
        </Box>
      </Grid>
    </Box>
  );
};
