import React, { Suspense } from 'react';
import { Switch } from 'react-router-dom';
import { ChakraProvider, Box, Grid, Flex } from '@chakra-ui/react';
import { useAuth0 } from '@auth0/auth0-react';
import customTheme, { layout } from './theme';

import { AppLoading } from './components/AppLoading';
import ProtectedRoute from './auth/ProtectedRoute';
import { RoutePage } from './pages/RoutePage';
import { RecoilRoot } from 'recoil';
import { HelmetProvider } from 'react-helmet-async';
import ExplorePage from './pages/ExplorePage';
import { HeaderWithContext } from './context/HeaderWithContext';
import { ErrorBoundary } from 'react-error-boundary';
import { ErrorSwitcher } from './components/Errors/switcher';
import { Spinner } from './components/Spinner';

export const App = () => {
  const { isLoading } = useAuth0();

  if (isLoading) {
    return <AppLoading />;
  }

  return (
    <ChakraProvider theme={customTheme}>
      <RecoilRoot>
        <HelmetProvider>
          <Grid templateRows="80px auto 200px" minH="100vh" fontSize="m">
            <HeaderWithContext />
            <Flex justify="center" mt={5} mb={5}>
              <Flex w={layout.maxContentWidth} alignItems="stretch">
                <ErrorBoundary
                  fallbackRender={(p: { error: Error }) => <ErrorSwitcher error={p.error} />}
                >
                  <Suspense fallback={<Spinner verticalPadding={16} />}>
                    <Switch>
                      <ProtectedRoute path="/route/:routeId" component={RoutePage} />
                      <ProtectedRoute path="/explore/:urlTab" component={ExplorePage} />
                    </Switch>
                  </Suspense>
                </ErrorBoundary>
              </Flex>
            </Flex>
            <Box>
              <Flex borderTopWidth={1} borderTopColor="gray.100" bg="gray.50" h="100%"></Flex>
            </Box>
          </Grid>
        </HelmetProvider>
      </RecoilRoot>
    </ChakraProvider>
  );
};
