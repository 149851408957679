import React from 'react';
import { Table, Tbody, Tr, Td, Thead, Th } from '@chakra-ui/react';
import { DateTime } from 'luxon';
import { milliseconds } from '../../types';

export interface Props {
  syncedAt: milliseconds;
  createdAt: milliseconds;
  updatedAt: milliseconds;
}

export const RouteDatesPanel: React.FC<Props> = function ({ syncedAt, createdAt, updatedAt }) {
  return (
    <Table variant="simple" mt={4}>
      <Thead>
        <Tr>
          <Th> </Th>
          <Th> </Th>
        </Tr>
      </Thead>
      <Tbody>
        <Tr>
          <Td>Last synchronised</Td>
          <Td>{DateTime.fromMillis(syncedAt).toLocaleString(DateTime.DATE_FULL)}</Td>
        </Tr>
        <Tr>
          <Td>Created at</Td>
          <Td>{DateTime.fromMillis(createdAt).toLocaleString(DateTime.DATE_FULL)}</Td>
        </Tr>
        <Tr>
          <Td>Updated at</Td>
          <Td>{DateTime.fromMillis(updatedAt).toLocaleString(DateTime.DATE_FULL)}</Td>
        </Tr>
      </Tbody>
    </Table>
  );
};
