import React, { useState } from 'react';
import ReactMapGL, {
  Source,
  Layer,
  LayerProps,
  ViewportProps,
  WebMercatorViewport,
  Marker,
} from 'react-map-gl';
import { CyclingRoute } from '../../state/route/route';
import { Feature, LineString } from 'geojson';
import theme from '../../theme';
import { decode } from '../../polyline';
import { toGeoJSON } from '@mapbox/polyline';
import bbox from '@turf/bbox';
import { Tooltip } from '@chakra-ui/react';
import { FinishIcon, StartIcon } from '../Icons';

export interface Props {
  route: CyclingRoute;
  height?: number;
  width?: number;
  padding?: number;
}

export const RouteMap: React.FC<Props> = ({ route, height = 500, width = 600, padding = 15 }) => {
  const geoJson = toGeoJSON(route.summaryPolyline);
  const [minLng, minLat, maxLng, maxLat] = bbox(geoJson);
  const centredViewport = new WebMercatorViewport({ width: width, height: height, zoom: 7 });
  const { longitude, latitude, zoom } = centredViewport.fitBounds(
    [
      [minLng, minLat],
      [maxLng, maxLat],
    ],
    {
      padding: padding,
    },
  );

  const [viewport, setViewport] = useState<ViewportProps>({
    width: width,
    height: height,
    latitude: latitude,
    longitude: longitude,
    zoom: zoom,
  });

  const handleViewportChange = (nextViewPort: ViewportProps) => {
    setViewport(nextViewPort);
  };

  const routePath: Feature<LineString> = {
    type: 'Feature',
    properties: {},
    geometry: {
      type: 'LineString',
      coordinates: decode(route.summaryPolyline),
    },
  };

  const routeLayer: LayerProps = {
    id: 'route-layer',
    type: 'line',
    layout: {
      'line-join': 'round',
      'line-cap': 'round',
    },
    paint: {
      'line-color': theme.colors.map.default,
      'line-width': 5,
    },
  };

  return (
    <ReactMapGL {...viewport} width="100%" onViewportChange={handleViewportChange} height={height}>
      <Marker
        longitude={route.startPoint.coordinates[1]}
        latitude={route.startPoint.coordinates[0]}
        offsetLeft={-8}
        offsetTop={-8}
      >
        <Tooltip label="Start">
          <StartIcon color={'primary.500'} boxSize={'16px'} />
        </Tooltip>
      </Marker>

      <Marker
        longitude={route.endPoint.coordinates[1]}
        latitude={route.endPoint.coordinates[0]}
        offsetLeft={-8}
        offsetTop={-8}
      >
        <Tooltip label="Finish">
          <FinishIcon color={'primary.500'} boxSize={'16px'} />
        </Tooltip>
      </Marker>

      {routePath ? (
        <Source id="route" type="geojson" data={routePath}>
          <Layer {...routeLayer} />
        </Source>
      ) : null}
    </ReactMapGL>
  );
};
