import React from 'react';
import { Box } from '@chakra-ui/react';
import { meters } from '../../types';

export interface DistanceFormatterProps {
  value: number; // should always be in meters
  precision?: number;
}

export const DistanceFormatter: React.FC<DistanceFormatterProps> = ({ value, precision = 2 }) => {
  return <Box>{`${(value / 1000).toFixed(precision)}`} km</Box>;

  // return <Box>{Math.round((value / 1000) * 0.62137)} mi</Box>;
};

export const formatDistance = function (value: meters, precision = 2) {
  return `${(value / 1000).toFixed(precision)} km`;
};
