import React from 'react';
import { Box, HStack, Image } from '@chakra-ui/react';
import connectWithStrava from './connect-strava.png';
import { SyncModalWithContext } from '../../context/SyncModalWithContext';
import { ProfileMenu } from './profile-menu';
import { ExploreButton } from '../ExploreButton';

export interface Props {
  onLogin: () => void;
  onLogout: () => void;
  isAuthenticated: boolean;
  userName: string;
  userPicture: string;
  onExploreMyRoutes: () => void;
}

export const QuickAccessBar: React.FC<Props> = ({
  onLogin,
  onLogout,
  isAuthenticated,
  userName,
  userPicture,
  onExploreMyRoutes,
}) => {
  return (
    <>
      {isAuthenticated ? (
        <HStack>
          <ExploreButton onClick={onExploreMyRoutes} />
          <SyncModalWithContext />
          <Box pl={3}>
            <ProfileMenu name={userName} picture={userPicture} onLogout={onLogout} />
          </Box>
        </HStack>
      ) : (
        <Box as="button" p={0} onClick={onLogin}>
          <Image src={connectWithStrava} h="2.5em" />
        </Box>
      )}
    </>
  );
};
