import React from 'react';
import { GenericError } from '../GenericError';
import { BrokenIcon } from '../Icons';

export const ServerSideError: React.FC = function () {
  return (
    <GenericError
      title="uh-oh spaghetti-o's"
      subtitle="It seems something went wrong beyond repair."
      code="501"
      icon={BrokenIcon}
    />
  );
};
