import React from 'react';
import { Flex, HStack, Spacer, Image } from '@chakra-ui/react';
import logo from './logo.png';
import { layout } from '../../theme';
import { QuickAccessBar } from './quick-access-bar';

export interface Props {
  onLogin: () => void;
  onLogout: () => void;
  isAuthenticated: boolean;
  userName: string;
  userPicture: string;
  onExploreMyRoutes: () => void;
}

export const Header: React.FC<Props> = ({
  onLogin,
  onLogout,
  isAuthenticated,
  userName,
  userPicture,
  onExploreMyRoutes,
}) => {
  return (
    <Flex
      w="100%"
      h="100%"
      p={0}
      borderTopWidth={6}
      borderTopColor={'primary.500'}
      borderBottomWidth={1}
      borderBottomColor={'gray.100'}
      justify="center"
    >
      <HStack w={layout.maxContentWidth}>
        <HStack>
          <Image src={logo} h={10} />
        </HStack>
        <Spacer />
        <QuickAccessBar
          onLogin={onLogin}
          onLogout={onLogout}
          isAuthenticated={isAuthenticated}
          userName={userName}
          userPicture={userPicture}
          onExploreMyRoutes={onExploreMyRoutes}
        />
      </HStack>
    </Flex>
  );
};

export default Header;
