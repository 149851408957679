import React from 'react';
import { meters } from '../../types';
import { HStack, Stat, StatLabel, StatNumber } from '@chakra-ui/react';
import { DistanceFormatter } from '../DistanceFormatter';
import { AltitudeFormatter } from '../AltitudeFormatter';
import { StarOutlineIcon, StarSolidIcon } from '../Icons';

export interface Props {
  distance: meters;
  elevationGain: meters;
  starred: boolean;
}

export const RouteBasicStatsPanel: React.FC<Props> = function ({
  distance,
  elevationGain,
  starred,
}) {
  return (
    <HStack w="100%" textAlign="center">
      <Stat size="md">
        <StatLabel>Distance</StatLabel>
        <StatNumber>
          <DistanceFormatter value={distance} />
        </StatNumber>
      </Stat>
      <Stat size="md">
        <StatLabel>Elevation Gain</StatLabel>
        <StatNumber>
          <AltitudeFormatter value={elevationGain} />
        </StatNumber>
      </Stat>

      <Stat size="md">
        <StatLabel>Starred</StatLabel>
        <StatNumber>
          {starred ? <StarSolidIcon color="yellow.500" /> : <StarOutlineIcon />}
        </StatNumber>
      </Stat>
    </HStack>
  );
};
