import React from 'react';
import { useRecoilState } from 'recoil';
import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { useHistory } from 'react-router-dom';
import {
  defaultStravaSyncState,
  StravaSyncAPIResp,
  stravaSyncState,
} from '../../state/routes-sync/routes-sync';
import { SyncModal } from '../../components/SyncModal';
import { useAuth0 } from '@auth0/auth0-react';
import { env } from '../../env';
import { getStravaIdFromSub } from '../../auth/utils';

export const SyncModalWithContext: React.FC = () => {
  const { user, getAccessTokenSilently } = useAuth0();
  const [syncState, setSyncState] = useRecoilState(stravaSyncState);
  const history = useHistory();

  const onSyncRequest = async () => {
    const token = await getAccessTokenSilently();
    const stravaAthleteId = getStravaIdFromSub(user?.sub);

    const reqConfig: AxiosRequestConfig = {
      method: 'GET',
      baseURL: env.api,
      url: '/sync',
      params: {
        athlete: stravaAthleteId,
      },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    try {
      const startedAt = new Date().toISOString();
      setSyncState({ ...syncState, state: 'running', startedAt: startedAt });
      const routesRes: AxiosResponse<StravaSyncAPIResp> = await axios.request(reqConfig);

      setSyncState({
        ...syncState,
        state: 'success',
        finishedAt: new Date().toISOString(),
        lastSync: {
          skipped: routesRes.data.skipped,
          updated: routesRes.data.updated,
          imported: routesRes.data.imported,
        },
      });
    } catch (e) {
      setSyncState({ ...syncState, state: 'error', finishedAt: new Date().toISOString() });

      console.error(e);
      throw e;
    }
  };

  const onEditLastSyncedRoutes = () => {};
  const onExploreRoutes = () => {
    history.push('/explore/last-sync');
  };

  const handleModalClose = function () {
    setSyncState(defaultStravaSyncState);
  };

  return (
    <SyncModal
      syncState={syncState}
      onSyncRequest={onSyncRequest}
      onEditLastSyncedRoutes={onEditLastSyncedRoutes}
      onExploreRoutes={onExploreRoutes}
      onModalClose={handleModalClose}
    />
  );
};
